import { TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Colors } from "../theme";

export const FooterTitle = styled(Typography)(() => ({
  textTransform: "uppercase",
  marginBottom: "1em",
}));
export const Subscribetf = styled(TextField)(() => ({
  ".MuiInputLabel-root": {
    color: Colors.secondary,
  },
  ".MuiInput-root::before": {
    borderBottom: `1px solid ${Colors.secondary}`,
  },
}));
// export const FooterTitle = styled(Typography)(({ theme }) => ({

//     [theme.breakpoints.down("md")]: {
//       width: "80%",
//       padding: "24px",
//     },
//   }));
