import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = (props) => {
  const user = useSelector((state) => state.user);
  //let role = localStorage.getItem("role") === "guest" ? false : true;
  let role = user.token === null ? false : true;

  return role ? <Outlet /> : <Navigate to="/?isLogin=false" />;
};
