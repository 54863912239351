import { configureStore } from "@reduxjs/toolkit";
//const reduxLogger = require("redux-logger");
import homeReducer from "../feutures/home/homeSlice";
import userReducer from "../feutures/auth/userSlice";
import uiReducer from "./uiSlice";

import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

const rootReducer = combineReducers({
  home: homeReducer,
  user: userReducer,
  ui: uiReducer,
});

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  //whitelist: ["ticket"],
  // blacklist: ["ticket"],
  blacklist: ["uiReducer", "navigator", "home"],
  transforms: [
    encryptTransform({
      secretKey: "adb-secret-key",
      onError: function (error) {
        process.env.NODE_ENV === "development" && console.log(error);
        // window.location.reload();
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
