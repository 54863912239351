import { Close, CloseOutlined, Height, Link, Login } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { toast } from "react-toastify";
import { useState } from "react";
const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function LoginDailog({ open, onClose }) {
  //const { drawerOpen, setDrawerOpen } = useUIContext();
  const ui = useSelector((state) => state.ui);

  //console.log({ ui });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const danUrl = useSelector((state) => state.user.danUrl);
  const [isLoading, setIsLoading] = useState(false);
  const loginDan = () => {
    //setLogin(true);
    // dispatch(getGuestToken());
    if (danUrl !== "") {
      setIsLoading(true);
      const timeout = setTimeout(() => {
        // 👇️ redirects to an external URL

        window.location.replace(danUrl);
        setIsLoading(true);
        // setLogin(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  };
  return (
    <>
      <BootstrapDialog
        // onClose={(event, reason) => {
        //   if (reason && reason === "backdropClick") {
        //     return;
        //   } else {
        //     return onClose();
        //   }
        //   // // console.log(reason);
        // }}

        maxWidth="lg"
        onClose={onClose}
        PaperProps={{
          style: {
            backgroundColor:
              theme.palette.mode === "dark" ? " #0D1B2A" : "#fff",
            backgroundImage: "none",
            borderRadius: "16px",
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* <DialogTitle sx={{ m: 0, p: 2, fontSize: ui.fontTitle1 }}>
          dsfdsfsd
        </DialogTitle> */}
        {/* <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton> */}
        <DialogContent dividers>
          <Box
            sx={{
              // width: "320px",
              // height: "321px",
              paddingTop: "64px",
              paddingRight: "50px",
              paddingLeft: "50px",
              paddingBottom: "50px",
              borderRadius: "16px",
            }}
          >
            <Stack direction={"column"} alignItems={"center"} spacing={2}>
              <img src="../../../../assets/danLogo.svg" alt="" width={150} />
              <Typography
                sx={{
                  fontSize: ui.fontTitle + "px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                ТАНИЛТ НЭВТРЭЛТИЙН <br />
                СИСТЕМ
              </Typography>
              <LoadingButton
                loading={isLoading}
                onClick={() => {
                  // showGrivanceDialog();
                  loginDan();
                }}
                size="medium"
                variant="contained"
                loadingPosition="start"
                sx={{
                  width: "100%",
                  fontSize: ui.fontContent + "px",
                  fontWeight: 600,
                  color: "#fff",
                  background: "#0B3599",
                  pl: "70px",
                  pr: "70px",
                  pb: "12px",
                  pt: "12px",
                  mb: "16px",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "#0B3599",
                  },
                }}
                //startIcon={<Login />}
              >
                Үргэлжлүүлэх
              </LoadingButton>
              {/* <Button
                size="medium"
                variant="contained"
                onClick={() => {
                  // showGrivanceDialog();
                  loginDan();
                }}
                sx={{
                  width: "100%",
                  fontSize: ui.fontContent + "px",
                  fontWeight: 600,
                  color: "#fff",
                  background: "#0B3599",
                  pl: "70px",
                  pr: "70px",
                  pb: "12px",
                  pt: "12px",
                  mb: "16px",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "#0B3599",
                  },
                }}
              >
                Үргэлжлүүлэх
              </Button> */}
            </Stack>
          </Box>
        </DialogContent>
        {/* <DialogActions sx={{ m: 1 }}>
          <Button
            sx={{
              color: "#ffff",
              background: "#0B3599",
              borderRadius: "8px",
              fontSize: ui.fontContent + "px",
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "12px",
              paddingBottom: "12px",
              "&:hover": {
                background:
                  theme.palette.mode === "dark" ? "#0B3599" : "#0B3599",
                boxShadow: "none",
              },
            }}
            autoFocus
            onClick={() => {
              // dispatch(setClearGiverance());
              // onClose();
              //submitFeedBack();
            }}
          >
            Илгээх
          </Button>
          <Button
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? " #25323F" : "#F3F5FA",
              fontSize: ui.fontContent + "px",
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "12px",
              paddingBottom: "12px",
            }}
            autoFocus
            onClick={() => {
              dispatch(setClearGiverance());
              onClose();
            }}
          >
            Хаах
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </>
  );
}
