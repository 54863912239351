import {
  Typography,
  List,
  IconButton,
  Card,
  Stack,
  Paper,
  TextField,
} from "@mui/material/";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Colors } from "../theme";
import { margin } from "polished";

//?container

export const FeedBackMyContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isDark",
})(({ isDark, theme }) => ({
  background: isDark ? " var(--Dark-90, #0D1B2A)" : "var(--Blue-10, #F3F5FA)",
  //width: "708px",
  // height: "221px",
  padding: "16px",
  gap: "8px",
  borderRadius: "16px",

  margin: "30px",
  [theme.breakpoints.down("md")]: {
    // width: "90%",
    // margin: "30px",
  },
  // [theme.breakpoints.down("sm")]: {
  //   width: "124px",
  //   height: "174px",
  // },
}));
