import {
  Cancel,
  Close,
  CloseOutlined,
  Height,
  Link,
  Search,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  InputAdornment,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/de";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  feedbackStoreFun,
  getDriverCheck,
  getViolationDecisionTypeFun,
  getViolationDetectionTypeFun,
  getViolationLegalPenaltyFun,
  getViolationLegalProvisionFun,
  getViolationOffenderFun,
  getViolationTypeDataFun,
  getviolationServiceTypeCrudFun,
  setClearGiverance,
  setOffenderRegister,
  setPenaltyAmount,
  setPlateValue,
  setRemoveFile,
  setUploadedFile,
  setValueAddress,
  setValueDecision,
  setValueDetection,
  setValueLegalPenalty,
  setValueLegalProvision,
  setValueViolation,
  setvalueService,
  vehicleSearchFun,
  violationEditFun,
} from "../../../feutures/home/homeSlice";
import { toast } from "react-toastify";
import { BusSearchField } from "../../../styles/home";

import { useEffect, useState } from "react";
import { Colors } from "../../../styles/theme";
import { getViolation } from "../../../feutures/auth/userSlice";
const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const itemData = [
  {
    img: "../../../../assets/emptyImage.svg",
    imgDark: "../../../../assets/emptyImageDark.svg",
  },
  {
    img: "../../../../assets/emptyImage.svg",
    imgDark: "../../../../assets/emptyImageDark.svg",
  },
  {
    img: "../../../../assets/emptyImage.svg",
    imgDark: "../../../../assets/emptyImageDark.svg",
  },
  {
    img: "../../../../assets/emptyImage.svg",
    imgDark: "../../../../assets/emptyImageDark.svg",
  },
];
const MAX_COUNT = 4;

export default function AddVailotion({ open, onClose, setSelectPage }) {
  //const { drawerOpen, setDrawerOpen } = useUIContext();
  const ui = useSelector((state) => state.ui);
  const valueTypeList = useSelector((state) => state.home.valueTypeList);
  // const transportRouteListData = useSelector(
  //   (state) => state.home.transportRouteListData
  // );
  // const transportRouteStopData = useSelector(
  //   (state) => state.home.transportRouteStopData
  // );
  const [dateVal, setDateVal] = useState(null);
  const [dateViolation, setDateViolation] = useState(null);
  // const [offenderRegister, setOffenderRegister] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const uploadedFile = useSelector((state) => state.home.uploadedFile);
  const plateValue = useSelector((state) => state.home.plateValue);
  const violationServiceTypeCrudList = useSelector(
    (state) => state.home.violationServiceTypeCrudList
  );
  const violationLegalPenaltyList = useSelector(
    (state) => state.home.violationLegalPenaltyList
  );

  const valueService = useSelector((state) => state.home.valueService);
  const valueLegalPenalty = useSelector(
    (state) => state.home.valueLegalPenalty
  );
  const valueLegalProvision = useSelector(
    (state) => state.home.valueLegalProvision
  );
  const violationLegalProvisionData = useSelector(
    (state) => state.home.violationLegalProvisionData
  );
  const violationDecisionTypeData = useSelector(
    (state) => state.home.violationDecisionTypeData
  );
  const violationDetectionTypeList = useSelector(
    (state) => state.home.violationDetectionTypeList
  );
  const violationTypeDataList = useSelector(
    (state) => state.home.violationTypeDataList
  );
  const busInfoData = useSelector((state) => state.home.busInfoData);
  const loading = useSelector((state) => state.home.loading);
  const driverCheckData = useSelector((state) => state.home.driverCheckData);

  const valueDecision = useSelector((state) => state.home.valueDecision);
  const valueDetection = useSelector((state) => state.home.valueDetection);
  const valueViolation = useSelector((state) => state.home.valueViolation);
  const penaltyAmount = useSelector((state) => state.home.penaltyAmount);
  const offenderRegister = useSelector((state) => state.home.offenderRegister);
  const valueAddress = useSelector((state) => state.home.valueAddress);
  const violationOffenderData = useSelector(
    (state) => state.home.violationOffenderData
  );
  const [fileLimit, setFileLimit] = useState(false);
  //console.log({ ui });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  // console.log(stopName);
  const { dialogOpen } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  useEffect(() => {
    if (violationServiceTypeCrudList === null) {
      dispatch(getviolationServiceTypeCrudFun());
    }
  }, [violationServiceTypeCrudList, dispatch]);
  useEffect(() => {
    if (violationLegalPenaltyList === null) {
      dispatch(getViolationLegalPenaltyFun());
    }
  }, [violationLegalPenaltyList, dispatch]);
  useEffect(() => {
    if (violationLegalProvisionData === null) {
      dispatch(getViolationLegalProvisionFun());
    }
  }, [violationLegalProvisionData, dispatch]);
  useEffect(() => {
    if (violationDecisionTypeData === null) {
      dispatch(getViolationDecisionTypeFun());
    }
  }, [violationDecisionTypeData, dispatch]);
  useEffect(() => {
    if (violationDetectionTypeList === null) {
      dispatch(getViolationDetectionTypeFun());
    }
  }, [violationDetectionTypeList, dispatch]);
  useEffect(() => {
    if (violationTypeDataList === null) {
      dispatch(getViolationTypeDataFun());
    }
  }, [violationTypeDataList, dispatch]);
  // console.log(violationServiceTypeCrudList);
  const handleUpdate = () => {
    if (plateValue.length === 7) {
      const data = {
        plate_no: plateValue,
      };
      //   // console.log(data);13-188
      dispatch(vehicleSearchFun(data));
    } else {
      toast.error("Улсын дугаар гүйцэд оруулна уу", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };
  const handleUploadFiles = (files) => {
    //  console.log(files);
    const uploaded = [...uploadedFiles];

    //console.log(uploaded);
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        // console.log(uploaded);
        dispatch(setUploadedFile(file));
        if (uploaded.length === MAX_COUNT) setFileLimit(true);

        if (uploaded.length > MAX_COUNT) {
          toast.error(`Хамгийн ихдээ ${MAX_COUNT} зураг оруулна уу!!!`, {
            position: "top-right",
            autoClose: 5000,
          });
          //  dispatch(setPosition("right"));
          // alert(`hamgiin ihdee ${MAX_COUNT}`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });

    if (!limitExceeded) setUploadedFiles(uploaded);
  };
  const handleChange = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    handleUploadFiles(chosenFiles);
  };
  const removeImage = (image, i) => {
    // console.log(image);
    var newFiles = [...uploadedFiles];
    dispatch(setRemoveFile(image));
    const currentIndex = uploadedFiles.indexOf(image);
    // const currentIndex2 = upload.indexOf(image);
    if (currentIndex !== -1) {
      //file.slice(currentIndex);
      newFiles.splice(uploadedFiles.indexOf(image), 1);
    }
    setUploadedFiles(newFiles);

    // console.log(newFiles);
  };
  const submitData = () => {
    const formData = new FormData();
    if (
      valueLegalPenalty !== null &&
      penaltyAmount !== "" &&
      busInfoData !== null &&
      violationOffenderData !== null &&
      valueService !== null &&
      valueAddress !== null &&
      valueLegalProvision !== null &&
      dateViolation !== null
    ) {
      if (uploadedFiles.length > 0) {
        Array.from(uploadedFiles).forEach((img) => {
          //  console.log(img);
          formData.append("attachment", img);
        });
      }
      formData.append("id", 0);
      formData.append("vehicle_id", busInfoData.id);

      formData.append("violation_date", dateViolation);
      formData.append("offender_id", violationOffenderData.id);
      formData.append("service_type_id", valueService.id);
      formData.append("legal_penalty_id", valueLegalPenalty.id);
      formData.append("legal_provision_id", valueLegalProvision.id);
      formData.append("decision_type_id", valueDecision.id);
      formData.append("detection_type_id", valueDetection.id);
      formData.append("type_id", valueDetection.id);
      // formData.append("citizen_type_id", valueCitizenType.id);
      // formData.append("company_id", valueCompany.id);
      formData.append("address", valueAddress);
      // console.log(dateViolation);
      dispatch(violationEditFun(formData)).then((res) => {
        if (res.payload.status.code === 200) {
          toast.success(`${res.payload.status.message}`, {
            position: "top-right",
            autoClose: 5000,
          });
          onClose();
          dispatch(getViolation());
          setSelectPage("myVailotionList");
        } else {
          toast.error(`${res.payload.status.message}`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
        console.log(res);
      });

      //  setOpen(false);
    } else {
      toast.error("Мэдээллээ гүйцэд оруулна уу!!!", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };
  // const submitFeedBack = () => {
  //   uploadedFile.length > 0 &&
  //     uploadedFile.length > 0 &&
  //     console.log(uploadedFile.length);
  //   if (
  //     stopName !== null &&
  //     routeList !== null &&
  //     uploadedFile.length > 0 &&
  //     valueTypeList !== null &&
  //     busInfoData !== null &&
  //     phoneNumber !== null
  //   ) {
  //     const formData = new FormData();
  //     formData.append("id", 0);
  //     formData.append("company_id", busInfoData.company_id);
  //     formData.append("source_id", 3);
  //     //  formData.append("route_id", routeList.id);
  //     formData.append("stop_id", stopName.stop_id);
  //     formData.append("vehicle_id", busInfoData.id);
  //     formData.append("type_id", valueTypeList.id);
  //     formData.append("note", note);
  //     formData.append("phone_number", phoneNumber);
  //     //  formData.append("attachment", uploadedFiles);
  //     if (uploadedFile.length > 0) {
  //       Array.from(uploadedFile).forEach((img) => {
  //         //  console.log(img);
  //         formData.append("attachment", img);
  //       });
  //     }
  //     dispatch(feedbackStoreFun(formData)).then((res) => {
  //       if (res.payload.status.code === 200) {
  //         toast.success(`Санал хүсэлт илгээсэнд баярлалаа`, {
  //           position: "top-right",
  //           autoClose: 5000,
  //         });
  //         onClose();
  //       } else {
  //         toast.error(`${res.payload.status.message}`, {
  //           position: "top-right",
  //           autoClose: 5000,
  //         });
  //       }
  //       console.log(res);
  //     });
  //     // dispatch(setVehicleData(null));
  //     //props.onClose();
  //   } else {
  //     toast.error(`Мэдээллээ гүйцэд оруулна уу!!!`, {
  //       position: "bottom-right",
  //       autoClose: 5000,
  //     });
  //   }
  // };
  // console.log(dateViolation);
  return (
    <>
      <BootstrapDialog
        maxWidth="lg"
        //onClose={onClose}
        fullScreen={matches ? true : false}
        onClose={(event, reason) => {
          if (reason && reason === "backdropClick") {
            return;
          } else {
            return onClose();
          }
          // // console.log(reason);
        }}
        PaperProps={{
          style: {
            backgroundColor:
              theme.palette.mode === "dark" ? " #0D1B2A" : "#fff",
            backgroundImage: "none",
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontSize: ui.fontTitle1 }}
          id="customized-dialog-title"
        >
          Зөрчил бүртгэх
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ mb: 2 }}>
            {" "}
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    color: "text.primary",
                    //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                    display: "flex",
                  }}
                >
                  <img src="../../../../assets/numburCircle1.svg" alt="" />
                  <Typography sx={{ ml: 1 }}>
                    Зөрчлийн эзэн тодруулах
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth sx={{}}>
                  <Typography
                    sx={{
                      //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                      color: "text.primary",
                      fontSize: ui.fontSmall + "px",
                      ml: 1,
                    }}
                  >
                    Улсын дугаар
                  </Typography>
                  <BusSearchField
                    size="small"
                    placeholder="0000УАА"
                    value={
                      //  isSearch
                      // ?
                      plateValue
                      // : getVehicleData !== null
                      // ? getVehicleData.plate_no
                      // : plateValue
                    }
                    inputProps={{ maxLength: 7 }}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      //  setIsSearch(true);
                      dispatch(setPlateValue(e.target.value));
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="pprimary"
                            disabled={loading ? true : false}
                            onClick={handleUpdate}
                            //   setIsSearch(false);
                            // } else {
                            //   dispatch(setLoading(false));
                            //   dispatch(setErrorSnackbarOpen(true));
                            //   dispatch(setInfoSnackbarOpen(true));
                            //   dispatch(
                            //     setSuccessSnackbarMessage(
                            //       "Улсын дугаар зөв оруулна уу!!!"
                            //     )
                            //   );
                            // }

                            edge="end"
                          >
                            <Search />
                            {/* {getIsLoading && plateValue !== "" ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Search />
                  )} */}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                {busInfoData !== null ? (
                  <Box
                    sx={{
                      // width: "100%",
                      // height: "140px",
                      m: 1,
                      padding: "16px",
                      background:
                        theme.palette.mode === "light" ? "#F3F5FA" : "#25323F",
                      borderRadius: "16px",
                    }}
                  >
                    <Box display={"flex"}>
                      <img src="../../../../assets/busIcon.svg" alt="" />
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}
                        ml={1}
                      >
                        Тээврийн хэрэгслийн мэдээлэл
                      </Typography>
                    </Box>

                    <table>
                      <tr>
                        <td>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: ui.fontSmall + "px",
                              color: "#868D94",
                              fontWeight: 600,
                            }}
                            color={"text.primay"}
                          >
                            Улсын дугаар:
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={"text.primay"}
                            sx={{
                              fontSize: ui.fontContent + "px",
                              fontWeight: 600,
                              ml: "3px",
                            }}
                          >
                            {busInfoData.plate_no}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: ui.fontSmall + "px",
                              color: "#868D94",
                              fontWeight: 600,
                            }}
                            color={"text.primay"}
                          >
                            Арлын дугаар:
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={"text.primay"}
                            sx={{
                              fontSize: ui.fontContent + "px",
                              fontWeight: 600,
                              ml: "3px",
                            }}
                          >
                            {busInfoData.cabin_no}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: ui.fontSmall + "px",
                              color: "#868D94",
                              fontWeight: 600,
                            }}
                            color={"text.primay"}
                          >
                            Марк, загвар:
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={"text.primay"}
                            sx={{
                              fontSize: ui.fontContent + "px",
                              fontWeight: 600,
                              ml: "3px",
                            }}
                          >
                            {`${busInfoData.mark_name} ${busInfoData.model_name}`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: ui.fontSmall + "px",
                              color: "#868D94",
                              fontWeight: 600,
                            }}
                            color={"text.primay"}
                          >
                            Өнгө:
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={"text.primay"}
                            sx={{
                              fontSize: ui.fontContent + "px",
                              fontWeight: 600,
                              ml: "3px",
                            }}
                          >
                            {busInfoData.color_name}
                          </Typography>
                        </td>
                      </tr>
                    </table>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth sx={{}}>
                  <Typography
                    sx={{
                      //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                      color: "text.primary",
                      fontSize: ui.fontSmall + "px",
                      ml: 1,
                    }}
                  >
                    Зөрчил гаргагч
                  </Typography>

                  <BusSearchField
                    size="small"
                    placeholder="Регистер"
                    value={
                      //  isSearch
                      // ?
                      offenderRegister
                      // : getVehicleData !== null
                      // ? getVehicleData.plate_no
                      // : plateValue
                    }
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      //  setIsSearch(true);
                      dispatch(setOffenderRegister(e.target.value));
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={loading ? true : false}
                            color="primary"
                            onClick={() => {
                              if (offenderRegister !== "") {
                                dispatch(
                                  getViolationOffenderFun({
                                    register: offenderRegister,
                                  })
                                );
                                dispatch(
                                  getDriverCheck({
                                    regnum: offenderRegister,
                                  })
                                );
                              } else {
                                toast.error("Регистрийн дугааар оруулна уу", {
                                  position: "top-right",
                                  autoClose: 5000,
                                });
                              }
                            }}
                            //   setIsSearch(false);
                            // } else {
                            //   dispatch(setLoading(false));
                            //   dispatch(setErrorSnackbarOpen(true));
                            //   dispatch(setInfoSnackbarOpen(true));
                            //   dispatch(
                            //     setSuccessSnackbarMessage(
                            //       "Улсын дугаар зөв оруулна уу!!!"
                            //     )
                            //   );
                            // }

                            edge="end"
                          >
                            <Search />
                            {/* {getIsLoading && plateValue !== "" ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Search />
                  )} */}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                {violationOffenderData !== null ? (
                  // driverCheckData
                  <Box
                    sx={{
                      // width: "100%",
                      // height: "140px",
                      m: 1,
                      padding: "16px",
                      background:
                        theme.palette.mode === "light" ? "#F3F5FA" : "#25323F",
                      borderRadius: "16px",
                    }}
                  >
                    <Box display={"flex"}>
                      <img src="../../../../assets/userPimary.svg" alt="" />
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}
                        ml={1}
                      >
                        Зөрчил гаргагчийн мэдээлэл
                      </Typography>
                    </Box>

                    <table>
                      <tr>
                        <td>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: ui.fontSmall + "px",
                              color: "#868D94",
                              fontWeight: 600,
                            }}
                            color={"text.primay"}
                          >
                            Овог:
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={"text.primay"}
                            sx={{
                              fontSize: ui.fontContent + "px",
                              fontWeight: 600,
                              ml: "3px",
                            }}
                          >
                            {violationOffenderData.lastname}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: ui.fontSmall + "px",
                              color: "#868D94",
                              fontWeight: 600,
                            }}
                            color={"text.primay"}
                          >
                            Нэр:
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={"text.primay"}
                            sx={{
                              fontSize: ui.fontContent + "px",
                              fontWeight: 600,
                              ml: "3px",
                            }}
                          >
                            {violationOffenderData.firstname}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: ui.fontSmall + "px",
                              color: "#868D94",
                              fontWeight: 600,
                            }}
                            color={"text.primay"}
                          >
                            Регистер
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={"text.primay"}
                            sx={{
                              fontSize: ui.fontContent + "px",
                              fontWeight: 600,
                              ml: "3px",
                            }}
                          >
                            {violationOffenderData.register}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: ui.fontSmall + "px",
                              color: "#868D94",
                              fontWeight: 600,
                            }}
                            color={"text.primay"}
                          >
                            Үндсэн жолооч эсэх:
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={"text.primay"}
                            sx={{
                              fontSize: ui.fontContent + "px",
                              fontWeight: 600,
                              ml: "3px",
                            }}
                          >
                            {violationOffenderData.is_basic === 0
                              ? "Тийм"
                              : "Үгүй"}
                          </Typography>
                        </td>
                      </tr>
                    </table>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth sx={{}}>
                  <Typography
                    sx={{
                      //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                      color: "text.primary",
                      fontSize: ui.fontSmall + "px",
                      //  ml: 1,
                    }}
                  >
                    Зөрчлийн огноо
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dateVal}
                      onChange={(newValue) => {
                        setDateVal(newValue);
                        const offsetStart = newValue.$d.getTimezoneOffset();
                        newValue = new Date(
                          newValue.$d.getTime() - offsetStart * 60 * 1000
                        );
                        const startDateValue = newValue
                          .toISOString()
                          .split("T")[0];
                        // console.log(startDateValue);
                        setDateViolation(startDateValue);
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </FormControl>
                {driverCheckData !== null ? (
                  // driverCheckData
                  <Box
                    sx={{
                      // width: "100%",
                      // height: "140px",
                      m: 1,
                      padding: "16px",
                      background:
                        theme.palette.mode === "light" ? "#F3F5FA" : "#25323F",
                      borderRadius: "16px",
                    }}
                  >
                    <Box display={"flex"}>
                      <img src="../../../../assets/userPimary.svg" alt="" />
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}
                        ml={1}
                      >
                        Жолооны үнэмлэх мэдээлэл
                      </Typography>
                    </Box>
                    <Box margin={1} display={"flex"}>
                      <img
                        src={driverCheckData.picture_url}
                        alt=""
                        width={60}
                        style={{ marginRight: "8px" }}
                      />
                      <Grid container spacing={3} justifyContent={"start"}>
                        {/* <Grid item xs={12} md={3}>
                          <Box>
                            <img
                              src={driverCheckData.picture_url}
                              alt=""
                              width={60}
                            />
                          </Box>
                        </Grid> */}

                        {driverCheckData.driver_license.classification.map(
                          (e) => {
                            //  const data = e.code + "-" + e.started_at;
                            if (e.started_at !== null) {
                              return (
                                <Grid item xs={"auto"} md={"auto"}>
                                  <Box
                                    sx={{
                                      color:
                                        theme.palette.mode === "light"
                                          ? "#868D94"
                                          : "#868D94",
                                      fontSize: ui.fontSmall + "px",
                                    }}
                                  >
                                    {e.code + " ангилал:"}
                                  </Box>{" "}
                                  <Typography
                                    sx={{
                                      fontSize: ui.fontContent + "px",
                                      color: "text.primary",
                                    }}
                                  >
                                    {e.started_at},
                                  </Typography>
                                  {/* <Typography variant="caption" border={1}>
                                {data},
                              </Typography> */}
                                </Grid>
                              );
                            }
                          }
                          // (e.started_at != null ? e.code : "")
                        )}
                      </Grid>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}

                        // ml={1}
                      >
                        Мэргэшсэн жолооны үнэмлэх мэдээлэл
                      </Typography>
                      <Grid container spacing={3} justifyContent={"start"}>
                        {/* <Grid item xs={12} md={3}>
                          <Box>
                            <img
                              src={driverCheckData.picture_url}
                              alt=""
                              width={60}
                            />
                          </Box>
                        </Grid> */}

                        {driverCheckData.pro_driver_license.classification.map(
                          (e) => {
                            //  const data = e.code + "-" + e.started_at;
                            if (e.expire_at !== "") {
                              return (
                                <Grid item xs={"auto"} md={"auto"}>
                                  <Box
                                    sx={{
                                      color:
                                        theme.palette.mode === "light"
                                          ? "#868D94"
                                          : "#868D94",
                                      fontSize: ui.fontSmall + "px",
                                    }}
                                  >
                                    {e.code + " ангилал:"}
                                  </Box>{" "}
                                  <Typography
                                    sx={{
                                      fontSize: ui.fontContent + "px",
                                      color: "text.primary",
                                    }}
                                  >
                                    {e.expire_at}
                                  </Typography>
                                  {/* <Typography variant="caption" border={1}>
                                {data},
                              </Typography> */}
                                </Grid>
                              );
                            }
                          }
                          // (e.started_at != null ? e.code : "")
                        )}
                      </Grid>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    color: "text.primary",
                    //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                    display: "flex",
                  }}
                >
                  <img src="../../../../assets/numburCircle2.svg" alt="" />
                  <Typography sx={{ ml: 1 }}>
                    Зөрчлийн эзэн тодруулах
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}
                  <Typography
                    sx={{
                      //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                      color: "text.primary",
                      fontSize: ui.fontSmall + "px",
                      // ml: 1,
                    }}
                  >
                    Үйлчилгээ
                  </Typography>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => {
                      return option.slug === value.slug;
                    }}
                    size="small"
                    value={valueService}
                    onChange={(event, newValue) => {
                      //console.log({ newValue });
                      dispatch(setvalueService(newValue));
                      // setTypeId(newValue.id);
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-esolo-with-text-demo444"
                    options={violationServiceTypeCrudList}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      // console.log(option);
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li key={option} {...props}>
                        {option.name}
                      </li>
                    )}
                    //   sx={{ width: 300 }}
                    // freeSolo
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Үйлчилгээ" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}
                  <Typography
                    sx={{
                      //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                      color: "text.primary",
                      fontSize: ui.fontSmall + "px",
                      // ml: 1,
                    }}
                  >
                    Хэрэг
                  </Typography>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => {
                      return option.slug === value.slug;
                    }}
                    size="small"
                    value={valueDecision}
                    onChange={(event, newValue) => {
                      //console.log({ newValue });
                      dispatch(setValueDecision(newValue));
                      // setTypeId(newValue.id);
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-esolo-with-text-demo444"
                    options={violationDecisionTypeData}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      // console.log(option);
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li key={option} {...props}>
                        {option.name}
                      </li>
                    )}
                    //   sx={{ width: 300 }}
                    // freeSolo
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Хэрэг" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}
                  <Typography
                    sx={{
                      //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                      color: "text.primary",
                      fontSize: ui.fontSmall + "px",
                      // ml: 1,
                    }}
                  >
                    Илрүүлэлт
                  </Typography>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => {
                      return option.slug === value.slug;
                    }}
                    size="small"
                    value={valueDetection}
                    onChange={(event, newValue) => {
                      //console.log({ newValue });
                      dispatch(setValueDetection(newValue));
                      // setTypeId(newValue.id);
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-esolo-with-text-demo444"
                    options={violationDetectionTypeList}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      // console.log(option);
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li key={option} {...props}>
                        {option.name}
                      </li>
                    )}
                    //   sx={{ width: 300 }}
                    // freeSolo
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Илрүүлэлт" />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Stack
            direction={matches ? "column" : "row"}
            // justifyContent={"space-between"}
            spacing={2}
          >
            <Box sx={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}
                    <Typography
                      sx={{
                        //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                        color: "text.primary",
                        fontSize: ui.fontSmall + "px",
                        // ml: 1,
                      }}
                    >
                      Хуулийн заалт
                    </Typography>
                    <Autocomplete
                      disabled={violationOffenderData !== null ? false : true}
                      isOptionEqualToValue={(option, value) => {
                        return option.slug === value.slug;
                      }}
                      size="small"
                      value={valueLegalPenalty}
                      onChange={(event, newValue) => {
                        //console.log({ newValue });
                        dispatch(setValueLegalPenalty(newValue));
                        if (violationOffenderData.citizen_type_id > 1) {
                          dispatch(
                            setPenaltyAmount(
                              new Intl.NumberFormat().format(newValue.amount)
                            )
                          );
                        } else {
                          dispatch(
                            setPenaltyAmount(
                              new Intl.NumberFormat().format(
                                newValue.amount_org
                              )
                            )
                          );
                        }
                        //   // setTypeId(newValue.id);
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-esolo-with-text-demo444"
                      options={violationLegalPenaltyList}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        // console.log(option);
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li key={option} {...props}>
                          {option.name}
                        </li>
                      )}
                      //   sx={{ width: 300 }}
                      // freeSolo
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Хуулийн заалт" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}
                    <Typography
                      sx={{
                        //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                        color: "text.primary",
                        fontSize: ui.fontSmall + "px",
                        // ml: 1,
                      }}
                    >
                      Хуулийн зүйл, хэсэг
                    </Typography>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => {
                        return option.slug === value.slug;
                      }}
                      size="small"
                      value={valueLegalProvision}
                      onChange={(event, newValue) => {
                        //console.log({ newValue });
                        dispatch(setValueLegalProvision(newValue));
                        // setTypeId(newValue.id);
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-esolo-with-text-demo444"
                      options={violationLegalProvisionData}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        // console.log(option);
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li key={option} {...props}>
                          {option.name}
                        </li>
                      )}
                      //   sx={{ width: 300 }}
                      // freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Хуулийн зүйл, хэсэг"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}
                    <Typography
                      sx={{
                        //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                        color: "text.primary",
                        fontSize: ui.fontSmall + "px",
                        // ml: 1,
                      }}
                    >
                      Зөрчил
                    </Typography>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => {
                        return option.slug === value.slug;
                      }}
                      size="small"
                      value={valueViolation}
                      onChange={(event, newValue) => {
                        //console.log({ newValue });
                        dispatch(setValueViolation(newValue));
                        // setTypeId(newValue.id);
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-esolo-with-text-demo444"
                      options={violationDetectionTypeList}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        // console.log(option);
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li key={option} {...props}>
                          {option.name}
                        </li>
                      )}
                      //   sx={{ width: 300 }}
                      // freeSolo
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Зөрчил" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}
                    <Typography
                      sx={{
                        //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                        color: "text.primary",
                        fontSize: ui.fontSmall + "px",
                        // ml: 1,
                      }}
                    >
                      Төлбөр
                    </Typography>
                    <FormControl sx={{ m: 0, width: "100% " }}>
                      {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}
                      <TextField
                        value={penaltyAmount + "₮"}
                        readonly={true}
                        required
                        id="standard-required"
                      />
                    </FormControl>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}
                    <Typography
                      sx={{
                        //  color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                        color: "text.primary",
                        fontSize: ui.fontSmall + "px",
                        // ml: 1,
                      }}
                    >
                      Хаяг
                    </Typography>
                    <FormControl sx={{ m: 0, width: "100% " }}>
                      {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}

                      <TextareaAutosize
                        maxRows={4}
                        aria-label="maximum height"
                        placeholder="Бичих"
                        onChange={(e) => {
                          dispatch(setValueAddress(e.target.value));
                        }}
                        style={{
                          width: "100%",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? " var(--Dark-90, #0D1B2A)"
                              : "var(--Blue-10, #fff)",
                          height: "100px",
                          p: 1,
                        }}
                      />
                    </FormControl>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: "100%" }}>
              <ImageList
                sx={{
                  height: 300,
                  overflow: "hidden",
                  height: "fit-content",
                }}
              >
                {uploadedFiles.length === 0
                  ? itemData.map((item, i) => (
                      <ImageListItem key={i}>
                        <img
                          key={i}
                          src={
                            theme.palette.mode === "dark"
                              ? `${item.imgDark}?w=248&fit=crop&auto=format`
                              : `${item.img}?w=248&fit=crop&auto=format`
                          }
                          alt={
                            "../../../../assets/emptyImage.svg?w=248&fit=crop&auto=format&dpr=2 2x"
                          }
                          loading="lazy"
                          style={{ borderRadius: "8px" }}
                        />
                      </ImageListItem>
                    ))
                  : uploadedFiles.map((item, i) => (
                      <ImageListItem key={i}>
                        <img
                          key={i}
                          src={URL.createObjectURL(item)}
                          srcSet={item}
                          alt={item}
                          loading="lazy"
                          style={{ borderRadius: "8px" }}
                        />
                        <IconButton
                          onClick={() => {
                            // console.log(i);
                            removeImage(item, i);
                          }}
                          sx={{ position: "absolute", right: -5, top: -5 }}
                        >
                          <Cancel sx={{ color: Colors.white }} />
                        </IconButton>
                        {/* <ImageListItemBar
                title={item.title}
                subtitle={<span>by: {item.author}</span>}
                position="below"
              /> */}
                      </ImageListItem>
                    ))}
              </ImageList>
              <FormControl sx={{ m: 0, width: "100% " }}>
                <Button
                  sx={{
                    height: "44px",
                    padding: "12px 16px 12px 16px",
                    background:
                      theme.palette.mode === "dark" ? "#25323F" : "#F3F5FA",
                    color: "text.primary",
                    borderRadius: "8px",
                    boxShadow: "none",
                    "&:hover": {
                      background:
                        theme.palette.mode === "dark" ? "#25323F" : "#F3F5FA",
                      boxShadow: "none",
                    },
                  }}
                  aria-label="upload picture"
                  component="label"
                  variant="contained"
                >
                  <input
                    multiple
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={handleChange}
                    type="file"
                  />
                  {/* <Image /> */}
                  <Typography sx={{ ml: 1, color: "text.primary" }}>
                    {" "}
                    Зураг хуулах
                  </Typography>
                </Button>
              </FormControl>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button
            sx={{
              color: "#ffff",
              background: "#0B3599",
              borderRadius: "8px",
              fontSize: ui.fontContent + "px",
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "12px",
              paddingBottom: "12px",
              "&:hover": {
                background:
                  theme.palette.mode === "dark" ? "#0B3599" : "#0B3599",
                boxShadow: "none",
              },
            }}
            autoFocus
            onClick={() => {
              submitData();
              // dispatch(setClearGiverance());
              // onClose();
              //    submitFeedBack();
            }}
          >
            Зөрчил бүртгэх
          </Button>
          <Button
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? " #25323F" : "#F3F5FA",
              fontSize: ui.fontContent + "px",
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "12px",
              paddingBottom: "12px",
            }}
            autoFocus
            onClick={() => {
              dispatch(setClearGiverance());
              onClose();
            }}
          >
            Хаах
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
