import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  fontTitle: 23,
  fontTitle1: 19,
  fontTitle2: 15,
  fontContent: 13,
  fontSmall: 12,
  drawerOpen: false,
  dialogOpen: false,
  error: "",
};

const homeSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    fontSizeIncrement: (state) => {
      state.fontTitle++;
      state.fontContent++;
      state.fontTitle1++;
      state.fontTitle2++;
      state.fontSmall++;
    },
    fontSizeDecrement: (state) => {
      state.fontTitle--;
      state.fontContent--;
      state.fontTitle1--;
      state.fontTitle2--;
      state.fontSmall--;
    },
    fontSizeNormal: (state) => {
      state.fontTitle = 24;
      state.fontContent = 14;
      state.fontTitle1 = 20;
      state.fontTitle2 = 16;
      state.fontSmall = 12;
    },
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setDialogOpen: (state, action) => {
      state.dialogOpen = action.payload;
    },
    // restocked: (state, action) => {
    //   state.numOfCackes += action.payload;
    // },
  },
});

export default homeSlice.reducer;
export const {
  fontSizeIncrement,
  fontSizeDecrement,
  fontSizeNormal,
  setDialogOpen,
  setDrawerOpen,
} = homeSlice.actions;
