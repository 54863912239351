import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import server from "../../config/server";
import { jwtDecode } from "jwt-decode";

import { toast } from "react-toastify";
import { persistStore } from "redux-persist";
const initialState = {
  loading: false,
  user: null,
  myVehicleList: null,
  grievanceMyData: null,
  violationData: null,
  permission: null,
  role: null,
  token: null,
  danUrl: "",
  isLogged: false,
  isExpired: false,
  error: "",
};
export const getDanUrl = createAsyncThunk("/user/loginByDan", (data) => {
  return (
    server
      .CallService({
        Url: "/user/loginByDan",
        Method: "post",
        Data: data,
      })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
export const loginDanApprove = createAsyncThunk("user/approve", (data) => {
  return (
    server
      .CallService({
        Url: "/user/approve",
        Method: "post",
        Data: { civil_data: data },
        //  ResponseType: "JSON",
      })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
export const getUserData = createAsyncThunk("user/data", () => {
  return (
    server
      .CallService({ Url: "user/data", Method: "POST" })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
export const grievanceMyFun = createAsyncThunk("/grievance/my", () => {
  return (
    server
      .CallService({ Url: "/grievance/my", Method: "POST" })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
export const getViolation = createAsyncThunk("/violation/list", () => {
  return (
    server
      .CallService({ Url: "/violation/list", Method: "POST" })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
// export const getMyVehicles = createAsyncThunk("vehicle/myVehicles", () => {
//   return (
//     server
//       .CallService({ Url: "vehicle/myVehicles", Method: "POST" })

//       //.then((response) => response.data.map((user) => user["id"]));
//       .then((response) => JSON.parse(response.data))
//   );
// });
// export const getGuestToken = createAsyncThunk("user/login", () => {
//   return (
//     server
//       .BaseGetList("user/login")

//       //.then((response) => response.data.map((user) => user["id"]));
//       .then((response) => response.data)
//   );
// });

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setExpire: (state, action) => {
      // console.log(action.payload);
      state.isExpired = action.payload;
    },
    clearUser(state = initialState) {
      return { ...state };
    },
    checkPermistion(state, action) {
      const permistion = state.user.permission.find(
        (e) => e.id === 5 && e.is_update === 1
      );
      state.permission = typeof permistion === "undefined" ? false : true;
    },
    // loginDanApprove: (state, action) => {
    //   // console.log(action.payload);
    //   //state.loading = true;

    //   let decodedToken = jwtDecode(action.payload);
    //   state.token = action.payload;
    //   state.role = decodedToken.role;
    //   state.isLogged = true;

    //   toast.success("Системд амжилттай нэвтэрлээ");
    // },
    logout: (state, action) => {
      state.user = null;
      state.token = null;
      state.role = null;
      // persistStore.purge();
      // localStorage.removeItem("persist:root");

      state.isLogged = false;
      state.loading = false;
      localStorage.removeItem("persist:root");
      // persistor.purge();
      window.location.reload();
      // state.user = null;
      // state.role = "";
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getGuestToken.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(getGuestToken.fulfilled, (state, action) => {
    //   state.loading = false;

    //   // localStorage.setItem("autoBoxToken", action.payload.token);
    //   // localStorage.setItem("role", action.payload.role);
    //   // console.log(action.payload);
    //   //tokenCheck();
    //   state.user = null;
    //   state.role = action.payload.role;
    //   state.token = action.payload.token;

    //   state.isExpired = false;
    //   state.error = "";
    // });
    // builder.addCase(getGuestToken.rejected, (state, action) => {
    //   state.loading = false;
    //   state.user = null;

    //   state.error = action.error.message;
    // });
    //--------
    builder.addCase(getUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.loading = false;
      state.isLogged = true;

      state.user = action.payload.data;
      state.role = action.payload.data.role;
      state.error = "";
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.loading = false;
      state.isLogged = false;
      state.user = null;
      state.error = action.error.message;
    });
    //--------getDanUrl
    builder.addCase(getDanUrl.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDanUrl.fulfilled, (state, action) => {
      state.loading = false;

      state.danUrl = action.payload.url;
      state.error = "";
    });
    builder.addCase(getDanUrl.rejected, (state, action) => {
      state.loading = false;
      state.danUrl = "";

      state.error = action.error.message;
    });
    //--------loginDanApprove
    builder.addCase(loginDanApprove.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginDanApprove.fulfilled, (state, action) => {
      state.loading = false;
      // console.log(action.payload.token);
      state.token = action.payload.token;
      state.isLogged = true;
      state.role = action.payload.role;
      state.error = "";
      //  document.location = "/";
    });
    builder.addCase(loginDanApprove.rejected, (state, action) => {
      state.loading = false;
      state.isLogged = false;
      state.token = null;
      state.role = null;

      state.error = action.error.message;
    });
    //--------grievanceMyFun
    builder.addCase(grievanceMyFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(grievanceMyFun.fulfilled, (state, action) => {
      state.loading = false;
      // console.log(action.payload.token);
      state.grievanceMyData = action.payload.data;
      state.error = "";
      //  document.location = "/";
    });
    builder.addCase(grievanceMyFun.rejected, (state, action) => {
      state.loading = false;
      state.grievanceMyData = null;

      state.error = action.error.message;
    });
    //--------getViolation
    builder.addCase(getViolation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getViolation.fulfilled, (state, action) => {
      state.loading = false;
      // console.log(action.payload.token);
      state.violationData = action.payload.data;
      state.error = "";
      //  document.location = "/";
    });
    builder.addCase(getViolation.rejected, (state, action) => {
      state.loading = false;
      state.violationData = null;

      state.error = action.error.message;
    });
  },
});

export default userSlice.reducer;
export const { setExpire, logout, checkPermistion } = userSlice.actions;
