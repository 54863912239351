import { Cancel } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  ImageList,
  ImageListItem,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";

import { useEffect, useState } from "react";

import {
  setNote,
  setPhoneNumber,
  setRemoveFile,
  setRouteList,
  setStopName,
  setTransportRouteStopData,
  setUploadedFile,
  setValueTypeList,
} from "../../../feutures/home/homeSlice";
import { Colors } from "../../../styles/theme";

import { toast } from "react-toastify";
import { BusSearchField } from "../../../styles/home";
const itemData = [
  {
    img: "../../../../assets/emptyImage.svg",
    imgDark: "../../../../assets/emptyImageDark.svg",
  },
  {
    img: "../../../../assets/emptyImage.svg",
    imgDark: "../../../../assets/emptyImageDark.svg",
  },
  {
    img: "../../../../assets/emptyImage.svg",
    imgDark: "../../../../assets/emptyImageDark.svg",
  },
  {
    img: "../../../../assets/emptyImage.svg",
    imgDark: "../../../../assets/emptyImageDark.svg",
  },
];
const MAX_COUNT = 4;
export default function FormsGrivanceComponent({ type, matches }) {
  //const { drawerOpen, setDrawerOpen } = useUIContext();
  const ui = useSelector((state) => state.ui);
  const theme = useTheme();

  const valueTypeList = useSelector((state) => state.home.valueTypeList);
  const transportRouteListData = useSelector(
    (state) => state.home.transportRouteListData
  );
  const transportRouteStopData = useSelector(
    (state) => state.home.transportRouteStopData
  );
  const routeList = useSelector((state) => state.home.routeList);
  const stopName = useSelector((state) => state.home.stopName);
  const uploadedFile = useSelector((state) => state.home.uploadedFile);
  const phoneNumber = useSelector((state) => state.home.phoneNumber);
  const { dialogOpen } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  // const toggleDrawer = (newOpen) => () => {
  //   dispatch(setDialogOpen(newOpen));
  // };
  // useEffect(() => {
  //   if (uploadedFiles.length === 0) {
  //     setUploadedFiles(
  //       new Array(MAX_COUNT).fill("../../../../assets/emptyImage.svg")
  //     );
  //   }
  // }, [uploadedFiles, MAX_COUNT]);
  console.log(uploadedFile);
  const handleUploadFiles = (files) => {
    //  console.log(files);
    const uploaded = [...uploadedFiles];

    //console.log(uploaded);
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        // console.log(uploaded);
        dispatch(setUploadedFile(file));
        if (uploaded.length === MAX_COUNT) setFileLimit(true);

        if (uploaded.length > MAX_COUNT) {
          toast.error(`Хамгийн ихдээ ${MAX_COUNT} зураг оруулна уу!!!`, {
            position: "top-right",
            autoClose: 5000,
          });
          //  dispatch(setPosition("right"));
          // alert(`hamgiin ihdee ${MAX_COUNT}`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });

    if (!limitExceeded) setUploadedFiles(uploaded);
  };
  const handleChange = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    handleUploadFiles(chosenFiles);
  };
  const removeImage = (image, i) => {
    // console.log(image);
    var newFiles = [...uploadedFiles];
    dispatch(setRemoveFile(image));
    const currentIndex = uploadedFiles.indexOf(image);
    // const currentIndex2 = upload.indexOf(image);
    if (currentIndex !== -1) {
      //file.slice(currentIndex);
      newFiles.splice(uploadedFiles.indexOf(image), 1);
    }
    setUploadedFiles(newFiles);

    // console.log(newFiles);
  };
  return (
    <>
      {/* <Typography color={Colors.primary}>Төрөл сонгох</Typography> */}

      <Stack
        direction={matches ? "column" : "row"}
        justifyContent={"space-between"}
        spacing={2}
      >
        <Box sx={{ width: "90%", pl: 1 }}>
          <Typography fontSize={ui.fontContent + "px"} color="text.primary">
            Төрөл сонгох
          </Typography>
          <Autocomplete
            key={0}
            autoComplete
            sx={{ mb: 2 }}
            size="small"
            id="free-esolo-with-text-demo"
            value={valueTypeList}
            // onSelectChange={handleOnChangeText}
            onChange={(event, newValue) => {
              //console.log({ newValue });
              // dispatch(setRouteList(newValue));
              dispatch(setValueTypeList(newValue));

              //   dispatch(
              //     transportRouteStopDataFun({
              //       id: newValue.id,
              //     })
              //   );
            }}
            selectOnFocus
            // clearOnBlur
            options={type}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              // console.log(option);
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            //   sx={{ width: 300 }}

            renderInput={(params) => (
              <TextField {...params} placeholder="Сонгох" />
            )}
          />
          <Typography fontSize={ui.fontContent + "px"} color="text.primary">
            Чиглэл сонгох
          </Typography>
          <Autocomplete
            key={0}
            autoComplete
            sx={{ mb: 2 }}
            size="small"
            id="free-esolo-with-text-demo"
            value={routeList}
            // onSelectChange={handleOnChangeText}
            onChange={(event, newValue) => {
              //console.log({ newValue });
              dispatch(setRouteList(newValue));
              dispatch(setTransportRouteStopData(newValue.stop));

              //   dispatch(
              //     transportRouteStopDataFun({
              //       id: newValue.id,
              //     })
              //   );
            }}
            selectOnFocus
            // clearOnBlur
            options={transportRouteListData}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              // console.log(option);
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            //   sx={{ width: 300 }}

            renderInput={(params) => (
              <TextField {...params} placeholder="Сонгох" />
            )}
          />
          <Typography fontSize={ui.fontContent + "px"} color="text.primary">
            Зогсоол сонгох
          </Typography>
          <Autocomplete
            key={1}
            sx={{ mb: 2 }}
            size="small"
            value={stopName}
            onChange={(event, newValue) => {
              dispatch(setStopName(newValue));

              //  setRouteId(newValue.id);
            }}
            selectOnFocus
            //  clearOnBlur
            handleHomeEndKeys
            id="free-esolo-with-text-demo"
            options={
              transportRouteStopData !== null ? transportRouteStopData : []
            }
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              // console.log(option);
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            //   sx={{ width: 300 }}

            renderInput={(params) => (
              <TextField {...params} placeholder="Сонгох" />
            )}
          />

          <Typography fontSize={ui.fontContent + "px"} color="text.primary">
            Нэмэлт мэдээлэл
          </Typography>
          <TextareaAutosize
            maxRows={4}
            aria-label="maximum height"
            placeholder="Бичих"
            onChange={(e) => {
              dispatch(setNote(e.target.value));
            }}
            style={{
              width: "100%",
              backgroundColor:
                theme.palette.mode === "dark"
                  ? " var(--Dark-90, #0D1B2A)"
                  : "var(--Blue-10, #fff)",
              height: "100px",
              p: 1,
            }}
          />
        </Box>
        <Stack direction="column" justifyContent={"space-between"}>
          <Typography sx={{ fontSize: ui.fontContent + "px", ml: 1 }}>
            Холбоо барих утас
          </Typography>
          <BusSearchField
            inputProps={{ maxLength: 8 }}
            sx={{
              input: {
                textTransform: "none",
              },
            }}
            value={phoneNumber}
            // type="number"
            size="small"
            // type="number"
            placeholder="Бичих"
            // label="Гарааш дугаараар хайх"
            onChange={(e) => {
              dispatch(setPhoneNumber(e.target.value));
              // // console.log(e.value);
              // setIsSearch(true);numburCircle1
            }}
          />
          <Box>
            <ImageList
              sx={{
                width: 400,
                height: 300,
                overflow: "hidden",
                height: "fit-content",
              }}
            >
              {uploadedFiles.length === 0
                ? itemData.map((item, i) => (
                    <ImageListItem key={i}>
                      <img
                        key={i}
                        src={
                          theme.palette.mode === "dark"
                            ? `${item.imgDark}?w=248&fit=crop&auto=format`
                            : `${item.img}?w=248&fit=crop&auto=format`
                        }
                        alt={
                          "../../../../assets/emptyImage.svg?w=248&fit=crop&auto=format&dpr=2 2x"
                        }
                        loading="lazy"
                        style={{ borderRadius: "8px" }}
                      />
                    </ImageListItem>
                  ))
                : uploadedFiles.map((item, i) => (
                    <ImageListItem key={i}>
                      <img
                        key={i}
                        src={URL.createObjectURL(item)}
                        srcSet={item}
                        alt={item}
                        loading="lazy"
                        style={{ borderRadius: "8px" }}
                      />
                      <IconButton
                        onClick={() => {
                          // console.log(i);
                          removeImage(item, i);
                        }}
                        sx={{ position: "absolute", right: -5, top: -5 }}
                      >
                        <Cancel sx={{ color: Colors.white }} />
                      </IconButton>
                      {/* <ImageListItemBar
                title={item.title}
                subtitle={<span>by: {item.author}</span>}
                position="below"
              /> */}
                    </ImageListItem>
                  ))}
            </ImageList>
          </Box>

          {/* <Box
            sx={
              uploadedFiles.length > 0
                ? { display: "none" }
                : { display: "block" }
            }
          >
            <ImageList sx={{ width: 500, height: 450 }}>
              {itemData.map((item, i) => (
                <ImageListItem key={i}>
                  <img
                    style={{ borderRadius: "8px" }}
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box> */}

          <FormControl sx={{ m: 0, width: "100% " }}>
            <Button
              sx={{
                height: "44px",
                padding: "12px 16px 12px 16px",
                background:
                  theme.palette.mode === "dark" ? "#25323F" : "#F3F5FA",
                color: "text.primary",
                borderRadius: "8px",
                boxShadow: "none",
                "&:hover": {
                  background:
                    theme.palette.mode === "dark" ? "#25323F" : "#F3F5FA",
                  boxShadow: "none",
                },
              }}
              aria-label="upload picture"
              component="label"
              variant="contained"
            >
              <input
                multiple
                accept="image/png, image/jpeg"
                hidden
                onChange={handleChange}
                type="file"
              />
              {/* <Image /> */}
              <Typography sx={{ ml: 1, color: "text.primary" }}>
                {" "}
                Зураг хуулах
              </Typography>
            </Button>
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
}
