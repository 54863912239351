import {
  Box,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { HomeTitle, MementoContainer } from "../../styles/home";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

export default function AdviceComponent() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const ui = useSelector((state) => state.ui);
  const renderAdvices = [0, 1, 2].map((item, index) => (
    <Grid
      item
      key={index}
      xs={12}
      sm={4}
      md={2}
      lg={1}
      xl={1}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <Box
        key={index}
        sx={
          matches
            ? {
                mb: 10,
                width: "100%",
                height: "auto",
                padding: "5px",
                borderRadius: "16px",
                border: "1px solid",
              }
            : {
                width: "100%",
                height: "568px",
                padding: "5px",
                borderRadius: "16px",
                border: "1px solid",
                mb: 10,
                // width: "436px",
              }
        }
      >
        <CardActionArea onClick={() => console.log("first")}>
          <Box sx={{ pt: "12px", pl: "112px", pr: "112px" }}>
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              image={
                index === 0
                  ? "../../../assets/attention.svg"
                  : index === 1
                  ? "../../../assets/driver.svg"
                  : index === 2
                  ? "../../../assets/call.svg"
                  : ""
              }
              // height={"181px"}
              // image={`../../../../assets/logo.svg`}
              title="Contemplative Reptile"
            />
          </Box>

          <CardContent>
            <Typography
              gutterBottom
              variant="h3"
              component="h2"
              sx={{
                fontSize: matches ? ui.fontSmall + "px" : ui.fontContent + "px",
                fontWeight: 700,
                // mb: "20px",
              }}
            >
              {index === 0
                ? "Зай барих"
                : index === 1
                ? "Жолоочид хандах"
                : index === 2
                ? "Тус болоорой"
                : ""}
            </Typography>

            {index === 0 ? (
              <Stack direction={"column"} spacing={1}>
                <Typography
                  sx={{
                    fontSize: matches
                      ? ui.fontSmall + "px"
                      : ui.fontContent + "px",
                    fontWeight: 500,
                    textAlign: "justify",
                    color: "text.primary",
                  }}
                  component="p"
                >
                  1. Түүнээс хол очиж зогсох
                </Typography>
                <Stack direction={"row"} spacing={1}>
                  <Typography>2.</Typography>
                  <Typography
                    sx={{
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                      fontWeight: 500,
                      textAlign: "justify",
                      color: "text.primary",
                    }}
                    component="p"
                  >
                    Хэрэв зай барих боломжгүй нөхцөлд байгаа бол өөрийн аюулгүй
                    байдлаа тооцоолоод түүнд тааламжгүй байгаагаа үг хэлээрээ
                    (миний биед битгий хүр, хүмүүсээ туслаарай энд надад дарамт
                    үзүүлээд байна г.м), биеийн хэлэмжээрээ илэрхийлэх
                    (дургүйцсэн харцаар харах, гарыг нь түлхэх г.м)
                  </Typography>
                </Stack>

                <Typography
                  sx={{
                    fontSize: matches
                      ? ui.fontSmall + "px"
                      : ui.fontContent + "px",
                    fontWeight: 500,
                    textAlign: "justify",
                    color: "text.primary",
                  }}
                  component="p"
                >
                  3. Өөр зорчигчдоос тусламж хүсэх
                </Typography>
              </Stack>
            ) : index === 1 ? (
              <Stack direction={"column"} spacing={1}>
                <Stack direction={"row"} spacing={1}>
                  <Typography>1.</Typography>
                  <Typography
                    sx={{
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                      fontWeight: 500,
                      textAlign: "justify",
                      color: "text.primary",
                    }}
                    component="p"
                  >
                    Жолоочид хэлж тухайн хүнийг нийтийн тээврээс буулгахыг хүсэх
                    (Өөрийн аюулгүй байдлаа тооцоолох)
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography>2.</Typography>
                  <Typography
                    sx={{
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                      fontWeight: 500,
                      textAlign: "justify",
                      color: "text.primary",
                    }}
                    component="p"
                  >
                    Хэрэв таны эсэргүүцлийг үл тоох, дахин тухайн үйлдлээ
                    гаргасан хэвээр байвал цагдаагийн 102 дугаарт дуудлага өгөх
                  </Typography>
                </Stack>

                <Stack direction={"row"} spacing={1}>
                  <Typography>3.</Typography>
                  <Typography
                    sx={{
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                      fontWeight: 500,
                      textAlign: "justify",
                      color: "text.primary",
                    }}
                    component="p"
                  >
                    Хэрэв жолооч танд бэлгийн дарамт үйлдвэл нийтийн тээврийн
                    зөрчил хүлээн авах 70004040 болон цагдаагийн 102 дугаар
                    утсанд нийтийн тээврийн чиглэл, байршил, хугацааг хэлж
                    гомдол гаргах
                  </Typography>
                </Stack>
              </Stack>
            ) : index === 2 ? (
              <Stack direction={"column"} spacing={1}>
                <Typography
                  sx={{
                    fontSize: matches
                      ? ui.fontSmall + "px"
                      : ui.fontContent + "px",
                    fontWeight: 500,
                    textAlign: "justify",
                    color: "text.secondary",
                  }}
                >
                  Хэрэв таны дэргэд хэн нэгэн бэлгийн дарамтад өртөж байгааг
                  анзаарсан, мэдсэн тохиолдолд:
                </Typography>
                <Stack direction={"row"} spacing={1}>
                  <Typography>1.</Typography>
                  <Typography
                    sx={{
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                      fontWeight: 500,
                      textAlign: "justify",
                      color: "text.primary",
                    }}
                    component="p"
                  >
                    Хохирогчоос зүгээр эсэхийг нь асууж эсвэл ямар нэгэн зүйлийн
                    талаар ярилцаж хүчирхийлэл үйлдэгчээс хамгаалж, туслаж
                    байгаагаа илэрхийлэх
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography>2.</Typography>
                  <Typography
                    sx={{
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                      fontWeight: 500,
                      textAlign: "justify",
                      color: "text.primary",
                    }}
                    component="p"
                  >
                    Аюулгүй гэж үзвэл бэлгийн дарамт үйлдэхгүй байх, үйлдэлээ
                    зогсоохыг үйлдэгч этгээдээс шаардах, цагдаад мэдээлнэ гэдгээ
                    сануулах”
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography>3.</Typography>
                  <Typography
                    sx={{
                      fontSize: matches
                        ? ui.fontSmall + "px"
                        : ui.fontContent + "px",
                      fontWeight: 500,
                      textAlign: "justify",
                      color: "text.primary",
                    }}
                    component="p"
                  >
                    Бэлгийн дарамт үйлдэгдсэн хугацаа, байршил, автобусны
                    чиглэлийг тэмдэглэн авч нийтийн тээврийн зөрчил хүлээн авах
                    70004040 болон 102 дугаарт дуудлага өгөх
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              ""
            )}
          </CardContent>
        </CardActionArea>
      </Box>
    </Grid>
  ));
  return (
    <Box
      sx={
        theme.palette.mode === "light"
          ? {
              backgroundImage: "url(../../../assets/advBackLight.svg)",
              backgroundPosition: "25% 75%",
              backgroundRepeat: "noRepeat ",
              backgroundSize: "cover",
              height: "100%",
            }
          : {
              // background: "#0D1B2A",
              backgroundRepeat: "noRepeat ",
              backgroundImage: "url(../../../assets/advBack.svg)",
              height: "100%",
              backgroundPosition: "25% 75%",

              backgroundSize: "cover",
            }
      }
    >
      <Container maxWidth={"xl"}>
        <Stack direction={"column"} alignItems={"center"}>
          <HomeTitle
            sx={{
              fontWeight: matches ? 600 : 700,
              fontSize: matches ? ui.fontContent + "px" : ui.fontTitle1 + "px",
              pt: 2,
            }}
          >
            ИРГЭДЭД ӨГӨХ ЗӨВЛӨМЖ
          </HomeTitle>
          <HomeTitle
            sx={{
              fontWeight: matches ? 600 : 700,
              fontSize: matches ? ui.fontContent + "px" : ui.fontTitle1 + "px",
              color: "text.secondary",
              maxWidth: "884px",
            }}
          >
            Та нийтийн тээврээр үйлчлүүлж байх үедээ бэлгийн дарамтад өртсөн
            тохиолдолд дараах зөвлөмжүүдийг анхаараарай.
          </HomeTitle>
          <HomeTitle
            sx={{
              fontWeight: 600,
              fontSize: matches ? ui.fontSmall + "px" : ui.fontContent + "px",
              color: "text.primary",
              // maxWidth: "884px",
            }}
          >
            Та нийтийн тээврээр үйлчлүүлж байх үедээ бэлгийн дарамтад өртсөн
            тохиолдолд дараах зөвлөмжүүдийг анхаараарай.
          </HomeTitle>

          <Grid
            item
            container
            spacing={{ xs: 2, md: 2, lg: 2 }}
            justifyContent={"space-between"}
            // sx={{ margin: "20px 4px 10px 4px" }}
            columns={{ xs: 12, sm: 8, md: 6, lg: 3.1 }}
          >
            {renderAdvices}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
