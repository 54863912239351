import {
  Box,
  Button,
  DialogActions,
  Grid,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ColorModeContext, Colors, tokens } from "../../styles/theme";
import { useContext, useEffect, useState } from "react";
import { useUIContext } from "../../context/ui";
import { useDispatch, useSelector } from "react-redux";
import { grievanceMyFun } from "../../feutures/auth/userSlice";
import {
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
} from "@mui/icons-material";
import { FeedBackMyContainer } from "../../styles/feedBackMy";
import BaseHelper from "../../helper/BaseHelper";
import StelektonListData from "../../utils/skeletonList";
//import image from "/images/Truck.png";
export default function MyFeedbackPage({ ui, matches }) {
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const theme = useTheme();
  const getGrievanceMyData = useSelector((state) => state.user.grievanceMyData);
  const loading = useSelector((state) => state.home.loading);
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [formData, setFormData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage] = useState(3);
  const [pagesize, setPagesize] = useState(3);
  useEffect(() => {
    if (getGrievanceMyData === null) {
      dispatch(grievanceMyFun());
    } else {
      setFormData(getGrievanceMyData);
    }
  }, [dispatch, getGrievanceMyData]);
  // console.log(formData);
  useEffect(() => {
    if (formData !== null) {
      setPagesize(Math.ceil(formData.length / cardsPerPage));
    }
  }, [formData]);
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards =
    formData !== null ? formData.slice(indexOfFirstCard, indexOfLastCard) : [];
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  // console.log(getGrievanceMyData);
  return (
    <Stack direction={"column"} spacing={3}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h6" sx={{ color: "text.primary" }}>
          Миний саналууд
        </Typography>
        <Box
          sx={{
            padding: "4px 4px 4px 8px",
            alignItems: "center",
            borderRadius: "8px",
            border: "1px solid",
            display: "flex",
          }}
        >
          <Typography sx={{ fontSize: ui.fontContent + "px" }}>
            {" "}
            Эрэмбэлэх
          </Typography>
          <KeyboardArrowDown />
        </Box>
      </Stack>

      {loading && getGrievanceMyData === null ? (
        <>
          <StelektonListData />
        </>
      ) : getGrievanceMyData === null ? (
        <Stack
          direction={"column"}
          justifyContent={"center"}
          spacing={12}
          alignSelf={"center"}
        >
          <Typography
            variant="body1"
            sx={{ color: "#C2C6CA" }}
            textAlign={"center"}
          >
            Та одоогоор санал илгээгээгүй байна.
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="body1"
              sx={{
                color: "text.primary",
                fontSize: ui.fontSmall + "px",
                mb: 1,
              }}
              textAlign={"center"}
            >
              Хэрвээ танд нийтийн тээврийн үйлчилгээтэй холбоотой иргэдээс
              төрийн байгууллага, албан тушаалтанд хандаж гаргах өргөдөл,
              гомдол, санал, хүсэлт гаргах, түүнийг байгууллага, албан
              тушаалтнаас шийдвэрлэх үйл ажиллагаанд санал, хүсэлт илгээх бол
              энд дарна уу.
            </Typography>
            <Button
              size="medium"
              variant="contained"
              onClick={() => {}}
              sx={{
                // width: "40%",
                fontSize: ui.fontContent + "px",
                fontWeight: 600,
                color: "#fff",
                textTransform: "none",
                background: "#0B3599",
                pl: "70px",
                pr: "70px",
                pb: "12px",
                pt: "12px",
                mb: "1px",
                borderRadius: "8px",
                "&:hover": {
                  background: "#0B3599",
                },
              }}
            >
              Санал, хүсэлт илгээх
            </Button>
          </Box>
        </Stack>
      ) : (
        currentCards.map((item, index) => (
          <FeedBackMyContainer
            isDark={theme.palette.mode === "light" ? false : true}
          >
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Санал хүсэлт төрөл:
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.type_name}
                </Typography>
              </Grid>

              <Grid item xs={"auto"}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Төлөв:
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.is_closed === 0 ? "Шийдвэрлээгүй" : "Шийдвэрлэсэн"}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                color: theme.palette.mode === "light" ? "#868D94" : "#868D94",
                fontSize: ui.fontSmall + "px",
              }}
            >
              Нэмэлт мэдээлэл:
            </Box>
            <Typography
              sx={{
                fontSize: ui.fontContent + "px",
                color: "text.primary",
                // textAlign: "center",
              }}
            >
              {item.note}
            </Typography>
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={6} md={4}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Аж ахуй нэгж:
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.company_name}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Улсын дугаар
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.plate_no}
                </Typography>
              </Grid>

              <Grid item xs={"auto"}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Эх сурвалж
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.source_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Саналын дугаар
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.id}
                </Typography>
              </Grid>

              <Grid item xs={"auto"}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Илгээсэн огноо
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {BaseHelper.getDateToStrFromStr(item.created_at)}
                </Typography>
              </Grid>
            </Grid>
          </FeedBackMyContainer>
        ))
      )}
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Pagination
          variant="outlined"
          shape="rounded"
          color="primary"
          defaultPage={1}
          count={pagesize}
          page={currentPage}
          onChange={handleChange}
        />
      </Box>
    </Stack>
  );
}
