import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import server from "../../config/server";

const initialState = {
  loading: true,
  services: null,
  stopName: null,
  transportRouteListData: null,
  transportRouteStopData: null,
  valueTypeList: null,
  routeList: null,
  note: null,
  phoneNumber: null,
  valueService: null,
  busInfoData: null,
  violationServiceTypeCrudList: null,
  violationLegalPenaltyList: null,
  violationLegalProvisionData: null,
  violationDecisionTypeData: null,
  violationDetectionTypeList: null,
  violationTypeDataList: null,
  violationOffenderData: null,
  driverCheckData: null,
  penaltyAmount: "",
  valueLegalPenalty: null,
  valueLegalProvision: null,
  valueDetection: null,
  offenderRegister: null,
  valueAddress: "",
  uploadedFile: [],
  plateValue: "",
  garage_no: "",
  error: "",
};
export const getViolationLegalProvisionFun = createAsyncThunk(
  "/violation/legal_provision/list",
  () => {
    return (
      server
        .CallService({ Url: "/violation/legal_provision/list", Method: "POST" })

        //.then((response) => response.data.map((user) => user["id"]));
        .then((response) => JSON.parse(response.data))
    );
  }
);
export const getDriverCheck = createAsyncThunk("driver/data", (data) => {
  return (
    server
      .CallService({ Url: "driver/data", Method: "POST", Data: data })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
export const violationEditFun = createAsyncThunk("/violation/edit", (data) => {
  return (
    server
      .CallService({ Url: "/violation/edit", Method: "POST", Data: data })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
export const getViolationOffenderFun = createAsyncThunk(
  "/violation/offender/nameList",
  (data) => {
    return (
      server
        .CallService({
          Url: "/violation/offender/nameList",
          Data: data,
          Method: "POST",
        })

        //.then((response) => response.data.map((user) => user["id"]));
        .then((response) => JSON.parse(response.data))
    );
  }
);
export const getViolationTypeDataFun = createAsyncThunk(
  "/violation/type/list",
  () => {
    return (
      server
        .CallService({ Url: "/violation/type/list", Method: "POST" })

        //.then((response) => response.data.map((user) => user["id"]));
        .then((response) => JSON.parse(response.data))
    );
  }
);
export const getViolationDetectionTypeFun = createAsyncThunk(
  "/violation/detection_type/list",
  () => {
    return (
      server
        .CallService({ Url: "/violation/detection_type/list", Method: "POST" })

        //.then((response) => response.data.map((user) => user["id"]));
        .then((response) => JSON.parse(response.data))
    );
  }
);
export const getViolationDecisionTypeFun = createAsyncThunk(
  "/violation/decision_type/list",
  () => {
    return (
      server
        .CallService({ Url: "/violation/decision_type/list", Method: "POST" })

        //.then((response) => response.data.map((user) => user["id"]));
        .then((response) => JSON.parse(response.data))
    );
  }
);
export const getviolationServiceTypeCrudFun = createAsyncThunk(
  "/violation/service_type/list",
  () => {
    return (
      server
        .CallService({ Url: "/violation/service_type/list", Method: "POST" })

        //.then((response) => response.data.map((user) => user["id"]));
        .then((response) => JSON.parse(response.data))
    );
  }
);
export const getViolationLegalPenaltyFun = createAsyncThunk(
  "/violation/legal_penalty/list",
  () => {
    return (
      server
        .CallService({ Url: "/violation/legal_penalty/list", Method: "POST" })

        //.then((response) => response.data.map((user) => user["id"]));
        .then((response) => JSON.parse(response.data))
    );
  }
);
export const fetchServices = createAsyncThunk("grievance/type/tree", (data) => {
  //console.log("first");
  return (
    server
      .CallService({
        Url: "grievance/type/tree",
        Method: "post",
        Data: data,
      })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
export const transportRouteListFun = createAsyncThunk(
  "transport/route/listDetails",
  (data) => {
    //console.log("first");
    return (
      server
        .CallService({
          Url: "transport/route/listDetails",
          Method: "post",
          Data: data,
        })

        //.then((response) => response.data.map((user) => user["id"]));
        .then((response) => JSON.parse(response.data))
    );
  }
);
export const feedbackStoreFun = createAsyncThunk("grievance/edit", (data) => {
  //console.log("first");
  return (
    server
      .CallService({
        Url: "grievance/edit",
        Method: "post",
        Data: data,
      })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
export const vehicleSearchFun = createAsyncThunk("vehicle/data", (data) => {
  //console.log("first");
  return (
    server
      .CallService({
        Url: "vehicle/data",
        Method: "post",
        Data: data,
      })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
export const busSearchFun = createAsyncThunk("vehicle/check", (data) => {
  //console.log("first");
  return (
    server
      .CallService({
        Url: "vehicle/check",
        Method: "post",
        Data: data,
      })

      //.then((response) => response.data.map((user) => user["id"]));
      .then((response) => JSON.parse(response.data))
  );
});
// export const fetchNews = createAsyncThunk("article/list", (data) => {
//   return (
//     server
//       .CallService({
//         Url: "article/list",
//         Method: "post",
//         Data: data,
//       })

//       //.then((response) => response.data.map((user) => user["id"]));
//       .then((response) => JSON.parse(response.data))
//   );
// });
const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setValueTypeList: (state, action) => {
      state.valueTypeList = action.payload;
    },
    setRouteList: (state, action) => {
      state.routeList = action.payload;
    },
    setStopName: (state, action) => {
      state.stopName = action.payload;
    },
    setNote: (state, action) => {
      state.note = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setPlateValue: (state, action) => {
      state.plateValue = action.payload;
    },
    setGarageNo: (state, action) => {
      state.garage_no = action.payload;
    },
    setvalueService: (state, action) => {
      state.valueService = action.payload;
    },
    setValueLegalPenalty: (state, action) => {
      state.valueLegalPenalty = action.payload;
    },
    setValueLegalProvision: (state, action) => {
      state.valueLegalProvision = action.payload;
    },
    setValueDecision: (state, action) => {
      state.valueDecision = action.payload;
    },
    setValueDetection: (state, action) => {
      state.valueDetection = action.payload;
    },
    setValueViolation: (state, action) => {
      state.valueViolation = action.payload;
    },
    setPenaltyAmount: (state, action) => {
      state.penaltyAmount = action.payload;
    },
    setOffenderRegister: (state, action) => {
      state.offenderRegister = action.payload;
    },
    setValueAddress: (state, action) => {
      state.valueAddress = action.payload;
    },
    setUploadedFile: (state, action) => {
      state.uploadedFile.push(action.payload);
    },
    setRemoveFile: (state, action) => {
      //  state.uploadedFile.push(action.payload);
      var newFiles = [...state.uploadedFile];
      const currentIndex = state.uploadedFile.indexOf(action.payload);
      // const currentIndex2 = upload.indexOf(image);
      if (currentIndex !== -1) {
        //file.slice(currentIndex);
        newFiles.splice(state.uploadedFile.indexOf(action.payload), 1);
      }
      state.uploadedFile = newFiles;
    },
    setTransportRouteStopData: (state, action) => {
      state.transportRouteStopData = action.payload;
    },

    setClearGiverance: (state, action) => {
      state.valueTypeList = null;
      state.routeList = null;
      state.stopName = null;
      state.transportRouteStopData = null;
      state.note = null;
      state.transportRouteListData = null;
      state.busInfoData = null;
      state.uploadedFile = [];
      state.plateValue = "";
      state.garage_no = "";
      state.valueLegalPenalty = null;
      state.valueService = null;
      state.valueDetection = null;
      state.valueDecision = null;
      state.valueViolation = null;
      state.penaltyAmount = "";
      state.offenderRegister = "";
      state.violationOffenderData = null;
      state.driverCheckData = null;
    },
    // restocked: (state, action) => {
    //   state.numOfCackes += action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.loading = false;
      state.services = action.payload.data;
      state.error = "";
    });
    builder.addCase(fetchServices.rejected, (state, action) => {
      state.loading = false;
      state.services = null;
      state.error = action.error.message;
    });
    //------------------------------------
    builder.addCase(transportRouteListFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(transportRouteListFun.fulfilled, (state, action) => {
      state.loading = false;
      state.transportRouteListData = action.payload.data;
      state.error = "";
    });
    builder.addCase(transportRouteListFun.rejected, (state, action) => {
      state.loading = false;
      state.transportRouteListData = null;
      state.error = action.error.message;
    });
    //------------------------------------
    builder.addCase(vehicleSearchFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(vehicleSearchFun.fulfilled, (state, action) => {
      state.loading = false;
      state.busInfoData = action.payload.data;
      state.plateValue = action.payload.data.plate_no;
      state.garage_no = action.payload.data.garage_no;
      state.error = "";
    });
    builder.addCase(vehicleSearchFun.rejected, (state, action) => {
      state.loading = false;
      state.busInfoData = null;
      state.error = action.error.message;
    });
    //------------------------------------
    //------------------------------------
    builder.addCase(busSearchFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(busSearchFun.fulfilled, (state, action) => {
      state.loading = false;
      state.busInfoData = action.payload.data;
      state.plateValue = action.payload.data.plate_no;
      state.garage_no = action.payload.data.garage_no;
      state.error = "";
    });
    builder.addCase(busSearchFun.rejected, (state, action) => {
      state.loading = false;
      state.busInfoData = null;
      state.error = action.error.message;
    });
    //------------------------------------
    builder.addCase(getviolationServiceTypeCrudFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getviolationServiceTypeCrudFun.fulfilled,
      (state, action) => {
        state.loading = false;
        state.violationServiceTypeCrudList = action.payload.data;

        state.error = "";
      }
    );
    builder.addCase(
      getviolationServiceTypeCrudFun.rejected,
      (state, action) => {
        state.loading = false;
        state.violationServiceTypeCrudList = null;
        state.error = action.error.message;
      }
    );
    //------------------------------------
    builder.addCase(getViolationLegalPenaltyFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getViolationLegalPenaltyFun.fulfilled, (state, action) => {
      state.loading = false;
      state.violationLegalPenaltyList = action.payload.data;

      state.error = "";
    });
    builder.addCase(getViolationLegalPenaltyFun.rejected, (state, action) => {
      state.loading = false;
      state.violationLegalPenaltyList = null;
      state.error = action.error.message;
    });
    //------------------------------------
    builder.addCase(getViolationLegalProvisionFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getViolationLegalProvisionFun.fulfilled,
      (state, action) => {
        state.loading = false;
        state.violationLegalProvisionData = action.payload.data;

        state.error = "";
      }
    );
    builder.addCase(getViolationLegalProvisionFun.rejected, (state, action) => {
      state.loading = false;
      state.violationLegalProvisionData = null;
      state.error = action.error.message;
    });
    //------------------------------------
    builder.addCase(getViolationDecisionTypeFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getViolationDecisionTypeFun.fulfilled, (state, action) => {
      state.loading = false;
      state.violationDecisionTypeData = action.payload.data;

      state.error = "";
    });
    builder.addCase(getViolationDecisionTypeFun.rejected, (state, action) => {
      state.loading = false;
      state.violationDecisionTypeData = null;
      state.error = action.error.message;
    });
    //------------------------------------
    builder.addCase(getViolationDetectionTypeFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getViolationDetectionTypeFun.fulfilled, (state, action) => {
      state.loading = false;
      state.violationDetectionTypeList = action.payload.data;

      state.error = "";
    });
    builder.addCase(getViolationDetectionTypeFun.rejected, (state, action) => {
      state.loading = false;
      state.violationDetectionTypeList = null;
      state.error = action.error.message;
    });
    //------------------------------------
    builder.addCase(getViolationTypeDataFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getViolationTypeDataFun.fulfilled, (state, action) => {
      state.loading = false;
      state.violationTypeDataList = action.payload.data;

      state.error = "";
    });
    builder.addCase(getViolationTypeDataFun.rejected, (state, action) => {
      state.loading = false;
      state.violationTypeDataList = null;
      state.error = action.error.message;
    });
    //------------------------------------
    builder.addCase(getViolationOffenderFun.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getViolationOffenderFun.fulfilled, (state, action) => {
      state.loading = false;
      state.violationOffenderData = action.payload.data[0];

      state.error = "";
    });
    builder.addCase(getViolationOffenderFun.rejected, (state, action) => {
      state.loading = false;
      state.violationOffenderData = null;
      state.error = action.error.message;
    });
    //------------------------------------
    builder.addCase(getDriverCheck.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDriverCheck.fulfilled, (state, action) => {
      state.loading = false;
      state.driverCheckData = action.payload.data;

      state.error = "";
    });
    builder.addCase(getDriverCheck.rejected, (state, action) => {
      state.loading = false;
      state.driverCheckData = null;
      state.error = action.error.message;
    });
  },
});

export default homeSlice.reducer;
export const {
  setValueTypeList,
  setRouteList,
  setStopName,
  setNote,
  setTransportRouteStopData,
  setClearGiverance,
  setPlateValue,
  setGarageNo,
  setRemoveFile,
  setUploadedFile,
  setPhoneNumber,
  setvalueService,
  setValueLegalPenalty,
  setValueLegalProvision,
  setValueDecision,
  setValueDetection,
  setValueViolation,
  setPenaltyAmount,
  setOffenderRegister,
  setValueAddress,
} = homeSlice.actions;
