/* eslint-disable no-undef */
import { useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery, Box, Pagination, Container } from "@mui/material";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SingleServicesDesktop from "./servicesListDesktop";

import { fetchServices } from "../../feutures/home/homeSlice";
import StelektonListData from "../../utils/skeletonList";
//import ReactPaginate from "react-paginate";

export default function ServicesList({ ui, loginDialog }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const isLoading = useSelector((state) => state.home.isLoading);
  const isLogged = useSelector((state) => state.user.isLogged);
  const services = useSelector((state) => state.home.services);

  const home = { services, isLoading };
  //console.log(home.services);
  const dispatch = useDispatch();
  useEffect(() => {
    if (home.services === null) {
      dispatch(fetchServices());
    }
  }, [home, dispatch]);
  // console.log(getNews.news);

  if (home.loading || home.services === null) {
    return (
      <>
        <StelektonListData data={8} />
      </>
    );
  } else {
    const renderNews = home.services.map((item, index) => (
      <Grid
        item
        key={index}
        xs={12}
        sm={4}
        md={2}
        lg={1}
        xl={1}
        display="flex"
        flexDirection={"column"}
        alignItems="center"
      >
        {matches ? (
          <SingleServicesDesktop
            item={item}
            matches={matches}
            ui={ui}
            isLogged={isLogged}
            loginDialog={loginDialog}
          />
        ) : (
          <SingleServicesDesktop
            item={item}
            matches={matches}
            ui={ui}
            isLogged={isLogged}
            loginDialog={loginDialog}
          />
        )}
      </Grid>
    ));

    return (
      <>
        <Container maxWidth={"xl"}>
          <Grid
            item
            container
            spacing={{ xs: 2, md: 2, lg: 2 }}
            justifyContent={"space-between"}
            // sx={{ margin: "20px 4px 10px 4px" }}
            columns={{ xs: 12, sm: 8, md: 8, lg: 4 }}
          >
            {renderNews}
          </Grid>
        </Container>
      </>
    );
  }
}
