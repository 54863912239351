import axios from "axios";
import { toast } from "react-toastify";

import CryptoJS from "crypto-js";

const BaseApiUrl =
  process.env.NODE_ENV === "development"
    ? "https://api.ptd.ub.gov.mn/api/v1"
    : "https://api.ptd.ub.gov.mn/api/v1";

const FileUrl =
  process.env.NODE_ENV === "development"
    ? "https://api.ptd.ub.gov.mn/api/v1"
    : "https://api.ptd.ub.gov.mn/api/v1";

const server = axios.create({ baseURL: BaseApiUrl });

//console.log("rrrrrrrr");
// const GetToken = function () {
//   return localStorage.getItem("autoBoxToken");
// }
const GetLocalToken = function () {
  const data = JSON.parse(localStorage.getItem("persist:root"));
  if (data !== null) {
    const bytes = CryptoJS.AES.decrypt(JSON.parse(data.user), "adb-secret-key");
    const str = bytes.toString(CryptoJS.enc.Utf8);
    const token = JSON.parse(str);
    return token.token;
  } else {
    return null;
  }
};
const GetToken = function () {
  //  return localStorage.getItem("autoBoxToken");
  return GetLocalToken();
};
//console.log(GetToken());
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Server: server,
  FileUrl: FileUrl,

  CallService: async function ({ Url, Data, Method, ResponseType }) {
    try {
      var Token = GetToken();
      // process.env.NODE_ENV === "development" &&
      //   console.log({ Url, Data, Method });
      var res = await server({
        method: Method ? Method : "POST",
        headers: { authorization: "Bearer " + Token },
        url: Url,
        data: Data,
        responseType: ResponseType ? ResponseType : "JSON",
      });
      //process.env.NODE_ENV === "development" && console.log({ res });
      return res;
    } catch (ex) {
      // return ex;
      // console.log(ex);
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      if (status === 200 && (status !== 401 || status !== 500)) {
        const res = JSON.parse(ex.response.data);
        // return res.status.message;
        //   //   console.log(res);

        toast.error(res.status.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  },

  BaseGetList: async function (CustomUrl) {
    try {
      var Token = GetToken();
      process.env.NODE_ENV === "development" && console.log({ CustomUrl });

      var res = await server({
        method: "GET",
        headers: { authorization: "Bearer" + Token },
        url: CustomUrl,
      });
      process.env.NODE_ENV === "development" && console.log({ res });
      return res;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      // if (status === 401) {
      //   document.location = "/login";
      // }
      // return {
      //   data: {
      //     Success: false,
      //     Data: [],
      //     Total: 0,
      //     Message: "Алдаа гарлаа",
      //   },
      // };
    }
  },

  BaseGetDetail: async function ({ ObjectName, Id, CustomUrl }) {
    try {
      process.env.NODE_ENV === "development" && console.log({ ObjectName, Id });
      var Token = GetToken();
      var res = await server({
        method: "GET",
        headers: { authorization: "Bearer " + Token },
        url: CustomUrl ? CustomUrl : "base/" + ObjectName + "/" + Id,
      });
      process.env.NODE_ENV === "development" && console.log({ res });
      return res;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      // if (status === 401) {
      //   document.location = "/login";
      // }
      // return {
      //   data: {
      //     Success: false,
      //     Data: null,
      //     Total: 0,
      //     Message: "",
      //   },
      //   status: ex.response ? ex.response.status : 0,
      // };
    }
  },

  BaseGetDetailAdvance: async function ({ ObjectName, ReqData, CustomUrl }) {
    try {
      process.env.NODE_ENV === "development" &&
        console.log({ ObjectName, ReqData });
      var Token = GetToken();
      var res = await server({
        method: "GET",
        headers: { authorization: "Bearer " + Token },
        url: CustomUrl
          ? CustomUrl
          : "base/" + ObjectName + "/get-detail" + ReqData,
      });
      process.env.NODE_ENV === "development" && console.log({ res });
      return res;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      // if (status === 401) {
      //   document.location = "/login";
      // }
      // return {
      //   data: {
      //     Success: false,
      //     Data: null,
      //     Total: 0,
      //     Message: "",
      //   },
      //   status: ex.response ? ex.response.status : 0,
      // };
    }
  },

  BaseCreate: async function ({ ObjectName, Data, CustomUrl }) {
    try {
      process.env.NODE_ENV === "development" &&
        console.log({ ObjectName, Data });
      var Token = GetToken();
      var res = await server({
        method: "POST",
        url: CustomUrl ? CustomUrl : "base/" + ObjectName,
        headers: { authorization: "Bearer " + Token },
        data: { Data: JSON.stringify(Data) },
      });
      process.env.NODE_ENV === "development" && console.log({ res });
      return res;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      // if (status === 401) {
      //   document.location = "/login";
      // }
      // return {
      //   data: {
      //     Success: false,
      //     Data: null,
      //     Total: 0,
      //     Message: "",
      //   },
      //   status: ex.response ? ex.response.status : 0,
      // };
    }
  },

  BaseUpdate: async function ({ ObjectName, Data, Id, CustomUrl }) {
    try {
      process.env.NODE_ENV === "development" &&
        console.log({ ObjectName, Data, Id });
      var Token = GetToken();
      var res = await server({
        method: "PUT",
        url: CustomUrl ? CustomUrl : "base/" + ObjectName + "/" + Id,
        headers: { authorization: "Bearer " + Token },
        data: { Data: JSON.stringify(Data) },
      });
      process.env.NODE_ENV === "development" && console.log({ res });
      return res;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      // if (status === 401) {
      //   document.location = "/login";
      // }
      // return {
      //   data: {
      //     Success: false,
      //     Data: null,
      //     Total: 0,
      //     Message: "",
      //   },
      //   status: ex.response ? ex.response.status : 0,
      // };
    }
  },

  BaseDelete: async function ({ ObjectName, Id, CustomUrl }) {
    try {
      process.env.NODE_ENV === "development" && console.log({ ObjectName, Id });
      var Token = GetToken();
      var res = await server({
        method: "DELETE",
        url: CustomUrl ? CustomUrl : "base/" + ObjectName + "/" + Id,
        headers: { authorization: "Bearer " + Token },
        Data: { Id },
      });
      process.env.NODE_ENV === "development" && console.log({ res });
      return res;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      if (status === 401) {
        document.location = "/login";
      }
      return {
        data: {
          Success: false,
          Data: null,
          Total: 0,
          Message: "",
        },
        status: ex.response ? ex.response.status : 0,
      };
    }
  },

  BaseDownloadFile: async function ({ FileInfo, CustomUrl }) {
    try {
      process.env.NODE_ENV === "development" && console.log({ FileInfo });
      var Token = GetToken();
      var ResponseData = await server({
        method: "GET",
        url: CustomUrl
          ? CustomUrl
          : "base/download-file?FileInfo=" + JSON.stringify(FileInfo),
        headers: { authorization: "Bearer " + Token },
        responseType: "blob",
      });
      var Data = ResponseData.data;
      console.log({ Data });
      // if (Data.Success === false) {
      //   return Data;
      // } else {
      if (
        FileInfo.OrginalName.indexOf(".pdf") > -1 ||
        FileInfo.OrginalName.indexOf(".PDF") > -1
      ) {
        const file = new Blob([Data], { type: "application/pdf" });
        var s = window.URL.createObjectURL(file);
        return s;
      } else {
        const url = window.URL.createObjectURL(new Blob([Data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", FileInfo.OrginalName);
        document.body.appendChild(link);
        link.click();
        return null;
      }

      // window.open(s);
      // }
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      if (status === 401) {
        document.location = "/login";
      }
      return {
        data: {
          Success: false,
          Data: null,
          Total: 0,
          Message: "",
        },
        status: ex.response ? ex.response.status : 0,
      };
    }
  },

  BaseUploadFile: async function ({
    Value,
    LinkedObjectInfo,
    ShowProgress,
    CustomUrl,
  }) {
    try {
      var formData = new FormData();
      process.env.NODE_ENV === "development" && console.log({ Value });
      formData.append("LinkedObjectInfo", JSON.stringify(LinkedObjectInfo));
      for (var l = 0; l < Value.length; l++) {
        if (Value[l].File) {
          formData.append(
            "File" + l,
            Value[l].File,
            Value[l].FileInfo.OrginalName
          );
        }
        formData.append("File" + l + "Info", JSON.stringify(Value[l].FileInfo));
      }
      const Token = GetToken();
      const ResponseData = await server({
        method: "POST",
        url: CustomUrl ? CustomUrl : "base/file-upload",
        headers: { authorization: "Bearer " + Token },
        data: formData,
        onUploadProgress: (e) => {
          process.env.NODE_ENV === "development" && console.log(e);
        }, //ShowProgress ? ShowProgress : undefined,
      });
      const Data = ResponseData.data;
      return Data;
    } catch (ex) {
      process.env.NODE_ENV === "development" && console.log(ex);
      var status = ex.response ? ex.response.status : 0;
      process.env.NODE_ENV === "development" && console.log({ status });
      if (status === 401) {
        document.location = "/login";
      }
      return {
        data: {
          Success: false,
          Data: null,
          Total: 0,
          Message: "",
        },
        status: ex.response ? ex.response.status : 0,
      };
    }
  },
};
