// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Stack,
  Box,
  ListItemButton,
  ListItemText,
  List,
  useMediaQuery,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import { persistor } from "../../app/store";
import {
  checkPermistion,
  getUserData,
  getViolation,
  grievanceMyFun,
  logout,
} from "./userSlice";
import { useTheme } from "@emotion/react";
import { MyMenuLinkMobile } from "../../styles/appbar";
import { BusSearchField } from "../../styles/home";
import ProfilePage from "../../components/profile/profile";
import MyFeedbackPage from "../../components/profile/myFeedback";
import Footer from "../../components/footer";
import VailotionListPage from "../../components/profile/vailotionList";
import useDialogModal from "../../hooks/useDailogModal";
import AddVailotion from "../../components/dialog/vailotion";

export const ProfileView = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const permission = useSelector((state) => state.user.permission);
  const theme = useTheme();
  const [VailotionDetialDialog, showVailotionDialog, closeDialog] =
    useDialogModal(AddVailotion);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const ui = useSelector((state) => state.ui);
  const [selectPage, setSelectPage] = useState("profile");
  useEffect(() => {
    if (user === null) {
      dispatch(getUserData());
    }
  }, [user, dispatch]);
  useEffect(() => {
    if (permission === null) {
      dispatch(checkPermistion());
    }
  }, [permission, dispatch]);
  // console.log(user);
  // console.log(permission);
  const renderPage = useCallback(() => {
    switch (selectPage) {
      case "profile":
        return <ProfilePage matches={matches} ui={ui} user={user} />;
      case "myFeedBack":
        return <MyFeedbackPage matches={matches} ui={ui} />;
      case "myVailotionList":
        return (
          <VailotionListPage
            matches={matches}
            setSelectPage={setSelectPage}
            ui={ui}
          />
        );

      default:
        return <>df</>;
    }
  }, [selectPage, matches, ui, user]);

  return (
    <>
      <Box
        sx={{
          paddingTop: "64px",
          paddingBottom: "64px",
          // height: `100vh`,
          // marginLeft: "122px",
          // height: `calc(100vh - 28px)`,
          height: "100%",
          minHeight: matches ? "100%" : "100vh",
          // height: "550px",
        }}
      >
        <Container maxWidth={"lg"}>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Box
                sx={{
                  minWidth: "250px",
                  // width: "324px",
                  // height: "344px",
                  padding: "32px 40px 44px 18px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? " #0D1B2A" : "#fff",
                  borderRadius: "16px",
                }}
              >
                <List>
                  <MyMenuLinkMobile>
                    <ListItemButton
                      onClick={() => {
                        setSelectPage("profile");
                      }}
                    >
                      <Box sx={{ mr: 1 }}>
                        {theme.palette.mode === "dark" ? (
                          <img
                            src="../../../assets/loginUserIconDark.svg"
                            alt=""
                          />
                        ) : (
                          <img src="../../../assets/loginUserIcon.svg" alt="" />
                        )}
                      </Box>
                      <ListItemText
                        sx={{
                          color: "text.primary",
                          "& span": {
                            color: "text.primary",
                            fontSize: `${ui.fontContent}px`,
                          },
                        }}
                      >
                        Хувийн мэдээлэл
                      </ListItemText>
                    </ListItemButton>
                  </MyMenuLinkMobile>

                  <MyMenuLinkMobile>
                    <ListItemButton
                      onClick={() => {
                        dispatch(grievanceMyFun());
                        setSelectPage("myFeedBack");
                      }}
                    >
                      <Box sx={{ mr: 1 }}>
                        {" "}
                        {theme.palette.mode === "dark" ? (
                          <img src="../../../assets/helpIconDark.svg" alt="" />
                        ) : (
                          <img src="../../../assets/helpIcon.svg" alt="" />
                        )}
                      </Box>
                      <ListItemText
                        sx={{
                          color: "text.primary",
                          "& span": {
                            color: "text.primary",
                            fontSize: `${ui.fontContent}px`,
                          },
                        }}
                      >
                        Миний саналууд
                      </ListItemText>
                    </ListItemButton>
                  </MyMenuLinkMobile>

                  {permission ? (
                    <MyMenuLinkMobile>
                      <ListItemButton
                        onClick={() => {
                          dispatch(getViolation());
                          setSelectPage("myVailotionList");
                        }}
                      >
                        <Box sx={{ mr: 1 }}>
                          {" "}
                          {theme.palette.mode === "dark" ? (
                            <img
                              src="../../../assets/contactIconDark.svg"
                              alt=""
                            />
                          ) : (
                            <img src="../../../assets/contactIcon.svg" alt="" />
                          )}
                        </Box>
                        <ListItemText
                          sx={{
                            color: "text.primary",
                            "& span": {
                              color: "text.primary",
                              fontSize: `${ui.fontContent}px`,
                            },
                          }}
                        >
                          Зөрчлийн жагсаалт
                        </ListItemText>
                      </ListItemButton>
                    </MyMenuLinkMobile>
                  ) : (
                    ""
                  )}
                  {permission ? (
                    <MyMenuLinkMobile>
                      <ListItemButton
                        onClick={() => {
                          showVailotionDialog();
                          //  setSelectPage("addVailotion");
                        }}
                      >
                        <Box sx={{ mr: 1 }}>
                          {" "}
                          {theme.palette.mode === "dark" ? (
                            <img
                              src="../../../assets/loginUserIconDark.svg"
                              alt=""
                            />
                          ) : (
                            <img
                              src="../../../assets/loginUserIcon.svg"
                              alt=""
                            />
                          )}
                        </Box>
                        <ListItemText
                          sx={{
                            color: "text.primary",
                            "& span": {
                              color: "text.primary",
                              fontSize: `${ui.fontContent}px`,
                            },
                          }}
                        >
                          Зөрчил бүртгэх
                        </ListItemText>
                      </ListItemButton>
                    </MyMenuLinkMobile>
                  ) : (
                    ""
                  )}
                  <Divider />
                  <MyMenuLinkMobile>
                    <ListItemButton
                      onClick={() => {
                        //  setDrawerOpen(false);

                        dispatch(logout());
                      }}
                    >
                      <Box sx={{ mr: 1 }}>
                        {" "}
                        {theme.palette.mode === "dark" ? (
                          <img
                            src="../../../assets/loginUserIconDark.svg"
                            alt=""
                          />
                        ) : (
                          <img src="../../../assets/loginUserIcon.svg" alt="" />
                        )}
                      </Box>
                      <ListItemText
                        sx={{
                          color: "text.primary",
                          "& span": {
                            color: "text.primary",
                            fontSize: `${ui.fontContent}px`,
                          },
                        }}
                      >
                        Сисмемээс гарах
                      </ListItemText>
                    </ListItemButton>
                  </MyMenuLinkMobile>
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box
                sx={{
                  // width: matches ? "90%" : "70%",
                  // height: "344px",
                  paddingRight: "5px",
                  paddingLeft: "5px",
                  paddingTop: "15px",
                  paddingBottom: "10px",
                  // padding: "32px 40px 44px 18px",
                  backgroundColor:
                    theme.palette.mode === "dark" ? " #0D1B2A" : "#fff",
                  borderRadius: "16px",
                }}
              >
                {renderPage()}
                {/* <ProfilePage matches={matches} ui={ui} user={user} /> */}
              </Box>
            </Grid>
          </Grid>
          <VailotionDetialDialog setSelectPage={setSelectPage} />
        </Container>
      </Box>
    </>
  );
};
