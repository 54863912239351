import {
  ArrowUpward,
  CampaignOutlined,
  CampaignTwoTone,
  DarkModeOutlined,
  Favorite,
  GifBox,
  LightModeOutlined,
  LocalShipping,
  LocalShippingOutlined,
  Logout,
  LogoutOutlined,
  Person,
  PersonOffOutlined,
  ShoppingCart,
  UsbOffRounded,
  UsbRounded,
  ViewInAr,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUIContext } from "../../context/ui";

import { ColorModeContext, Colors } from "../../styles/theme";

import { useNavigate } from "react-router-dom";
import {
  ActionIconsContainerDesktop,
  ActionIconsContainerMobile,
  MyList,
} from "../../styles/appbar";
import {
  fontSizeDecrement,
  fontSizeIncrement,
  fontSizeNormal,
} from "../../app/uiSlice";
// v6

export default function Actions({ matches }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();

  const logedUser = useSelector((state) => state.user.user);

  const colorMode = useContext(ColorModeContext);

  const settings = ["Хувийн мэдээлэл", "Миний зар", "Системээс гарах"];
  const dispatch = useDispatch();
  function isEmptyObject(obj) {
    return JSON.stringify(obj) === "{}";
  }
  //console.log(theme.palette.mode);
  //console.log(logedUser);
  //   useEffect(() => {
  //     if (!isEmptyObject(logedUser)) {
  //       setShowLoginBox(false);
  //     }
  //   }, [logedUser]);
  // useEffect(() => {
  //   if (travelAddCardLocal) {
  //     JSON.parse(travelAddCardLocal).map((item) => {
  //       dispatch(setTravelAddCardData(item));
  //     });

  //     // dispatch(setTravelCartCount(travelCartCountLocal));
  //   }
  // }, []);
  // useEffect(() => {
  //   if (cargoAddCardLocal) {
  //     JSON.parse(cargoAddCardLocal).map((item) => {
  //       dispatch(setCargoAddCardData(item));
  //     });

  //     // dispatch(setTravelCartCount(travelCartCountLocal));
  //   }
  // }, []);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const logOut = async () => {
    //console.log("ggfgf");
    // await dispatch(logout());
  };

  const handleClick = (event, type) => {
    //console.log(type);
    setAnchorEl(event.currentTarget);
    //  setShowCartType(type);

    // navigate("/adsCartDetial");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    // console.log(event);
  };
  const menuSettingClick = (menu) => {
    //console.log(menu);
    switch (menu) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/myAds");
        break;
      case 2:
        logOut();
        break;

      default:
        break;
    }
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    //console.log(event);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const Component = matches
    ? ActionIconsContainerMobile
    : ActionIconsContainerDesktop;
  return (
    <Component>
      <MyList type="row" sx={{ alignSelf: "center" }}>
        <ListItemButton
          sx={{
            justifyContent: "center",
          }}
          onClick={colorMode.toggleColorMode}
        >
          <ListItemIcon
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "text.primary",
              // color: Colors.dark,
            }}
          >
            {theme.palette.mode === "light" ? (
              <DarkModeOutlined />
            ) : (
              <LightModeOutlined />
            )}
          </ListItemIcon>
        </ListItemButton>
        <Divider orientation="vertical" flexItem />
        <ListItemButton
          sx={{
            justifyContent: "center",
          }}
          onClick={(e) => {
            dispatch(fontSizeDecrement());
          }}
        >
          <ListItemIcon
            sx={{
              display: "flex",
              justifyContent: "center",
              // color: matches && Colors.dark,
            }}
          >
            {/* <ViewInAr /> */}

            {theme.palette.mode === "dark" ? (
              <img src="../../../assets/textDecDark.svg" alt="" />
            ) : (
              <img src="../../../assets/textDec.svg" alt="" />
            )}
          </ListItemIcon>
        </ListItemButton>
        <Divider orientation="vertical" flexItem />
        <ListItemButton
          sx={{
            justifyContent: "center",
          }}
          onClick={(e) => {
            dispatch(fontSizeNormal());
          }}
        >
          <ListItemIcon
            sx={{
              display: "flex",
              justifyContent: "center",
              // color: matches && Colors.secondary,
            }}
          >
            {/* <ViewInAr /> */}

            {theme.palette.mode === "dark" ? (
              <img src="../../../assets/textNormalDark.svg" alt="" />
            ) : (
              <img src="../../../assets/textNormal.svg" alt="" />
            )}
          </ListItemIcon>
        </ListItemButton>

        <Divider orientation="vertical" flexItem />
        <ListItemButton
          sx={{
            justifyContent: "center",
          }}
          onClick={(e) => {
            dispatch(fontSizeIncrement());
          }}
        >
          <ListItemIcon
            // onMouseEnter={handleMouseEnterTravel}
            // onMouseLeave={handleMouseLeaveTravel}
            sx={{
              display: "flex",
              justifyContent: "center",

              // color: matches && Colors.secondary,
            }}
          >
            {theme.palette.mode === "dark" ? (
              <img src="../../../assets/textPlusDark.svg" alt="" />
            ) : (
              <img src="../../../assets/textPlus.svg" alt="" />
            )}
          </ListItemIcon>
        </ListItemButton>
        <Divider orientation="vertical" flexItem />
      </MyList>
      {/* // {showTravelCart && ( */}

      {/* //)} */}
    </Component>
  );
}
