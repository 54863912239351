import {
  Typography,
  List,
  IconButton,
  Card,
  Stack,
  Paper,
  TextField,
} from "@mui/material/";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Colors } from "../theme";
import { margin } from "polished";

//?container
export const BusSearchField = styled(TextField)(({ theme }) => ({
  input: {
    textTransform: "uppercase",
  },
  ".MuiInputLabel-root": {
    color: Colors.secondary,
  },
  ".MuiInput-root": {
    fontSize: "1rem",
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
  color: Colors.secondary,
  ".MuiInputLabel-root::before": {
    borderBottom: `1px solid ${Colors.secondary}`,
  },
  padding: "0 0 0 10px",
}));
export const HomeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  padding: "10px 1px",
  // background: Colors.white,
  marginTop: 12,
  marginBottom: 12,

  borderRadius: "16px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));
export const HomeTitle = styled(Typography)(({ theme }) => ({
  lineHeight: 1.5,
  //  fontSize: "28px",
  textAlign: "center",
  marginTop: 12,
  textTransform: "uppercase",
  marginBottom: "20px",
  // color: Colors.primary,
  [theme.breakpoints.down("sm")]: {
    // fontSize: "20px",
  },
}));
export const BannerPageImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  alignSelf: "center",
  width: "58px",
  height: "58px",
  mt: 1,

  [theme.breakpoints.down("md")]: {
    width: "50px",
    // height: "300px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "48px",
    height: "48px",
  },
}));
export const HeadBackroundImage = styled(Box)(({ src, theme }) => ({
  backgroundImage: `url(${src})`,
  backgroundRepeat: "noRepeat ",
  backgroundPosition: "25% 75%",

  backgroundSize: "cover",
  display: "flex",
  //paddingTop: 15,
  //width: "100vw",
  height: "auto",
  paddingBottom: 42,
  [theme.breakpoints.down("md")]: {
    width: "100vw",
    height: "auto",
    backgroundPosition: "25% 55%",
  },
  // [theme.breakpoints.down("sm")]: {
  //   width: "124px",
  //   height: "174px",
  // },
}));
export const ServiceSwiperSlide = styled(Card)(({ theme }) => ({
  width: "362px",
  height: "408px",

  borderImage:
    "linear-gradient(0deg, rgba(23, 90, 168, 0.5), rgba(23, 90, 168, 0.5));",

  borderRadius: "24px",
  // background: "rgba(23, 90, 168, 0.1)",

  border: "1px solid rgba(23, 90, 168, 0.5)",
  // [theme.breakpoints.down("md")]: {
  //   width: "100vw",
  //   height: "auto",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   width: "124px",
  //   height: "174px",
  // },
}));
export const ServiceTitle = styled(Typography)(({ theme }) => ({
  fontSize: "32px",
  width: "500px",
  borderBottom: "2px solid #175AA8",
  borderImage:
    "linear-gradient(90deg, rgba(23, 90, 168, 0.3) 0%, rgba(23, 90, 168, 0) 100%)",
  borderImageSlice: 1,
  // [theme.breakpoints.down("md")]: {
  //   width: "100vw",
  //   height: "auto",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   width: "124px",
  //   height: "174px",
  // },
}));
export const ServiceTitleRightContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  border: "2px solid rgba(23, 90, 168, 0.3)",
  marginRight: "121px",

  width: "104px",
  height: "50px",

  padding: "13px, 24px, 13px, 24px",
  borderRadius: "46px",
  // [theme.breakpoints.down("md")]: {
  //   width: "100vw",
  //   height: "auto",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   width: "124px",
  //   height: "174px",
  // },
}));
export const ServiceTitleRight = styled(Typography)(({ theme }) => ({
  marginTop: "11px",
  color: "#175AA8",
  fontSize: "14px",
  fontWeight: 600,
  // [theme.breakpoints.down("md")]: {
  //   width: "100vw",
  //   height: "auto",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   width: "124px",
  //   height: "174px",
  // },
}));
export const AboutSectionContainer = styled(Box)(({ src, theme }) => ({
  backgroundImage: `url(${src})`,
  backgroundRepeat: "noRepeat ",
  backgroundPosition: "25% 15%",
  backgroundColor: Colors.primary,
  height: "700px",

  backgroundSize: "cover",
  display: "flex",

  [theme.breakpoints.down("md")]: {
    width: "100vw",
    height: "auto",
  },
  // [theme.breakpoints.down("sm")]: {
  //   width: "124px",
  //   height: "174px",
  // },
}));
export const AboutSectionTitle = styled(Box)(({ theme }) => ({
  height: "68px",
  displa: "flex",
  //alignSelf: "center",
  // width: "500px",

  color: Colors.white,
  borderBottom: "3px solid #FFCF5F",
  borderImage:
    " linear-gradient(90deg, #FFCF5F 0%, rgba(255, 207, 95, 0) 100%)",
  boxShadow: "0px 10px 10px -13px #110",
  borderImageSlice: 1,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    fontSize: "24px",
  },
}));
export const MementoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDark",
})(({ isDark, theme }) => ({
  display: "flex",
  width: "884px",
  height: "auto",
  textAlign: "justify",
  padding: "16px 32px 16px 32px",
  background: isDark ? " var(--Dark-90, #25323F)" : "var(--Blue-10, #E7EBF5)",
  height: "94px",
  borderRadius: "16px",
  margin: "30px",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    height: "auto",
    textAlign: "justify",
    margin: "30px",
  },
  // [theme.breakpoints.down("sm")]: {
  //   width: "124px",
  //   height: "174px",
  // },
}));
export const ServicesContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isDark",
})(({ isDark, theme }) => ({
  // display: "flex",
  //width: "884px",

  height: "auto",
  // padding: "16px 32px 16px 32px",
  background: isDark ? " var(--Dark-90, #0D1B2A)" : "var(--Blue-10, #fff)",

  margin: "30px",
  [theme.breakpoints.down("md")]: {
    // width: "90%",
    // margin: "30px",
  },
  // [theme.breakpoints.down("sm")]: {
  //   width: "124px",
  //   height: "174px",
  // },
}));
