import {
  Close,
  CloseOutlined,
  Height,
  Link,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import { setDialogOpen } from "../../../app/uiSlice";
import { BusSearchField } from "../../../styles/home";
import { useEffect, useState } from "react";
import {
  busSearchFun,
  setGarageNo,
  setPlateValue,
  vehicleSearchFun,
} from "../../../feutures/home/homeSlice";
import { toast } from "react-toastify";

export default function BusSearchComponent({ matches }) {
  //const { drawerOpen, setDrawerOpen } = useUIContext();
  const ui = useSelector((state) => state.ui);
  const busInfoData = useSelector((state) => state.home.busInfoData);
  const plateValue = useSelector((state) => state.home.plateValue);
  const garage_no = useSelector((state) => state.home.garage_no);
  const loading = useSelector((state) => state.home.loading);
  const theme = useTheme();

  //const { dialogOpen } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const [isSearch, setIsSearch] = useState(false);
  // const toggleDrawer = (newOpen) => () => {
  //   dispatch(setDialogOpen(newOpen));
  // };
  const handleUpdate = () => {
    if (plateValue.length === 7) {
      const data = {
        plate_no: plateValue,
        is_approved: 1,
      };
      //   // console.log(data);13-188
      dispatch(busSearchFun(data));
    } else {
      toast.error("Улсын дугаар гүйцэд оруулна уу", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      <Stack direction={"column"} spacing={2}>
        <Stack direction={matches ? "column" : "row"} spacing={1}>
          <Stack direction={"column"}>
            <Typography sx={{ fontSize: ui.fontContent + "px", ml: 1 }}>
              Улсын дугаараар хайх
            </Typography>
            <BusSearchField
              size="small"
              placeholder="0000УАА"
              value={
                //  isSearch
                // ?
                plateValue
                // : getVehicleData !== null
                // ? getVehicleData.plate_no
                // : plateValue
              }
              inputProps={{ maxLength: 7 }}
              onChange={(e) => {
                // console.log(e.target.value);
                setIsSearch(true);
                dispatch(setPlateValue(e.target.value));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      disabled={loading ? true : false}
                      onClick={handleUpdate}
                      //   setIsSearch(false);
                      // } else {
                      //   dispatch(setLoading(false));
                      //   dispatch(setErrorSnackbarOpen(true));
                      //   dispatch(setInfoSnackbarOpen(true));
                      //   dispatch(
                      //     setSuccessSnackbarMessage(
                      //       "Улсын дугаар зөв оруулна уу!!!"
                      //     )
                      //   );
                      // }

                      edge="end"
                    >
                      <Search />
                      {/* {getIsLoading && plateValue !== "" ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Search />
                  )} */}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack direction={"column"}>
            <Typography sx={{ fontSize: ui.fontContent + "px", ml: 1 }}>
              Гарааш дугаараар хайх
            </Typography>
            <BusSearchField
              value={garage_no}
              size="small"
              // type="number"
              placeholder="00-000"
              // label="Гарааш дугаараар хайх"
              onChange={(e) => {
                dispatch(setGarageNo(e.target.value));
                // // console.log(e.value);
                // setIsSearch(true);numburCircle1
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        const regex = /[-]/;
                        // dispatch(setLoading(true));
                        // console.log(garage_no.search(regex));
                        // console.log("dfgfdg");
                        if (
                          garage_no.search(regex) !== -1 &&
                          garage_no.length >= 5
                        ) {
                          const data = {
                            garage_no: garage_no,
                            is_approved: 1,
                          };

                          dispatch(busSearchFun(data));
                          setIsSearch(false);
                        } else {
                          toast.error("Граш дугаар зөв оруулна уу", {
                            position: "top-right",
                            autoClose: 5000,
                          });
                        }
                      }}
                      edge="end"
                    >
                      <Search />
                      {/* {getIsLoading && garage_no !== "" ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Search />
                  )} */}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
        {busInfoData !== null ? (
          <Box
            sx={{
              // width: "100%",
              // height: "140px",
              padding: "16px",
              background:
                theme.palette.mode === "light" ? "#F3F5FA" : "#25323F",
              borderRadius: "16px",
            }}
          >
            <Stack
              direction={matches ? "column" : "row"}
              justifyContent={"start"}
              spacing={matches ? 1 : 32}
            >
              <Box>
                <Box display={"flex"}>
                  <img src="../../../../assets/busIcon.svg" alt="" />
                  <Typography
                    variant="body1"
                    sx={{ fontSize: ui.fontContent + "px", fontWeight: 600 }}
                    color={"text.primay"}
                    ml={1}
                  >
                    Автобусны мэдээлэл
                  </Typography>
                </Box>
                <table>
                  <tr>
                    <td>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontSmall + "px",
                          color: "#868D94",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}
                      >
                        Улсын дугаар:
                      </Typography>
                    </td>
                    <td>
                      <Typography
                        color={"text.primay"}
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                          ml: "3px",
                        }}
                      >
                        {busInfoData.plate_no}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontSmall + "px",
                          color: "#868D94",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}
                      >
                        Арлын дугаар:
                      </Typography>
                    </td>
                    <td>
                      <Typography
                        color={"text.primay"}
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                          ml: "3px",
                        }}
                      >
                        {busInfoData.cabin_no}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontSmall + "px",
                          color: "#868D94",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}
                      >
                        Марк, загвар:
                      </Typography>
                    </td>
                    <td>
                      <Typography
                        color={"text.primay"}
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                          ml: "3px",
                        }}
                      >
                        {`${busInfoData.mark_name} ${busInfoData.model_name}`}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontSmall + "px",
                          color: "#868D94",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}
                      >
                        Өнгө:
                      </Typography>
                    </td>
                    <td>
                      <Typography
                        color={"text.primay"}
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                          ml: "3px",
                        }}
                      >
                        {busInfoData.color_name}
                      </Typography>
                    </td>
                  </tr>
                </table>
              </Box>

              <Box>
                <Box display={"flex"}>
                  <img src="../../../../assets/buildIcon.svg" alt="" />
                  <Typography
                    variant="body1"
                    sx={{ fontSize: ui.fontContent + "px", fontWeight: 600 }}
                    ml={1}
                  >
                    Аж ахуй нэгжийн мэдээлэл
                  </Typography>
                </Box>
                <table>
                  <tr>
                    <td>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontSmall + "px",
                          color: "#868D94",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}
                      >
                        Компани нэр:
                      </Typography>
                    </td>
                    <td>
                      <Typography
                        color={"text.primay"}
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                          ml: "3px",
                        }}
                      >
                        {busInfoData.company_name}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontSmall + "px",
                          color: "#868D94",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}
                      >
                        Утас:
                      </Typography>
                    </td>
                    <td>
                      <Typography
                        color={"text.primay"}
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                          ml: "3px",
                        }}
                      >
                        -
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: ui.fontSmall + "px",
                          color: "#868D94",
                          fontWeight: 600,
                        }}
                        color={"text.primay"}
                      >
                        Хаяг:
                      </Typography>
                    </td>
                    <td>
                      <Typography
                        color={"text.primay"}
                        sx={{
                          fontSize: ui.fontContent + "px",
                          fontWeight: 600,
                          ml: "3px",
                        }}
                      >
                        -
                      </Typography>
                    </td>
                  </tr>
                </table>
              </Box>
            </Stack>
          </Box>
        ) : (
          ""
        )}
      </Stack>
    </>
  );
}
