import { Container, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  BannerContainer,
  BannerContent,
  BannerDescription,
  BannerImage,
  BannerShopButton,
  BannerTitle,
} from "../../styles/banner";

import { useDispatch, useSelector } from "react-redux";

import { useContext } from "react";
import { Colors } from "../../styles/theme";
import { logout } from "../../feutures/auth/userSlice";
//import image from "/images/Truck.png";
export default function Banner({ theme, openLogin, closeLogin }) {
  //const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const logedUser = useSelector((state) => state.user.user);
  const isLogged = useSelector((state) => state.user.isLogged);
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  //console.log(theme.palette.mode);
  // console.log(isLogged);
  return (
    <Container maxWidth="xl" sx={matches ? { padding: 0 } : {}}>
      <BannerContainer sx={{}}>
        <BannerImage src="../../../assets/bus1.png" />
        <BannerContent>
          <Typography
            sx={{
              fontSize: matches ? ui.fontSmall + "px" : ui.fontContent + "px",
              fontWeight: 500,
            }}
            // sx={{ fontSize: "24px", fontWeight: 700 }}
          >
            Нийслэлийн нийтийн тээвэр
          </Typography>
          <BannerTitle
            sx={{
              fontSize: matches ? ui.fontTitle1 + "px" : ui.fontTitle + "px",
              fontWeight: 700,
            }}
          >
            {" "}
            САНАЛ, ХҮСЭЛТ, ГОМДОЛ, ТАЛАРХЛЫН ТАЛААРХ МЭДЭЭЛЭЛ ХҮЛЭЭН АВАХ СИСТЕМ
          </BannerTitle>
          <BannerDescription
            sx={{ fontSize: ui.fontContent + "px", fontWeight: 500 }}
          >
            Энэхүү системийн зорилго нь нийтийн тээврийн үйлчилгээтэй холбоотой
            иргэдээс төрийн байгууллага, албан тушаалтанд хандаж гаргасан
            өргөдөл, гомдол, санал, хүсэлт гаргах, түүнийг байгууллага, албан
            тушаалтнаас шийдвэрлэх үйл ажиллагааг хүртээмжтэй, ил тод байлгах
            харилцааг зохицуулахад оршино.
          </BannerDescription>
          {!isLogged ? (
            <BannerShopButton
              onClick={() => {
                openLogin();
              }}
              color="secondary"
              sx={{ fontSize: `${ui.fontContent}px`, color: Colors.dark }}
            >
              ДАН системээр нэвтрэх
            </BannerShopButton>
          ) : (
            <BannerShopButton
              sx={{ fontSize: `${ui.fontContent}px`, color: Colors.dark }}
              onClick={() => {
                dispatch(logout());
              }}
              color="secondary"
            >
              {" "}
              Системээс гарах
            </BannerShopButton>
          )}
        </BannerContent>
      </BannerContainer>
    </Container>
  );
}
