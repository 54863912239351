import { Typography, List, IconButton } from "@mui/material/";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Colors } from "../theme";
//import "@fontsource/montez";
import { Link } from "react-router-dom";
import { lighten } from "polished";
//?container
export const AppBarContainer = styled(Box)(() => ({
  display: "flex",

  // marginTop: "40px",
  justifyContent: "space-between",
  alignItems: "start",
  // padding: "2px 8px",
}));

//? header

export const AppBarHeader = styled(Typography)(() => ({
  padding: "2px",
  flexGrow: 1,

  // fontSize: "14px",
  //fontFamily: '"Montez", "cursive"',
  // color: Colors.primary,
}));
//? myList

export const MyList = styled(List)(({ type }) => ({
  display: type === "row" ? "flex" : "block",
  flexGrow: 3,
  justifyContent: "center",
  alignItems: "center",
  fontSize: "14px",
}));
export const MyMenuLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "isDark",
})(({ isDark }) => ({
  textDecoration: "none",
  fontFamily: "rubik",
  //textTransform: "uppercase",
  flexGrow: 1,
  color: isDark ? Colors.white : Colors.dark,
  //fontSize: fontSizes,
  fontWeight: 500,
  // background: "#FFFFFF1A",
  padding: "13px, 32px, 13px, 32px",
  //marginLeft: "13px",
  // border: "1.79px solid #FFFFFF1A",
  /// borderColor: Colors.white,
  // borderRadius: "46px",

  "&:hover": {
    background: Colors.primary,
    color: "#fff",
    // border: `1.79px solid ${Colors.border}`,
    // borderColor: Colors.border,
    borderRadius: 0,
  },
  "&:active": {
    background: Colors.primary,
    color: "#fff",
    // border: "1.79px solid #fff",
    // borderColor: Colors.secondary,
    //  borderRadius: "46px",
  },
  "&:focus,  &:visited, &:link, ": {
    textDecoration: "none",
  },
}));
export const ActionIconsContainerMobile = styled(Box)(() => ({
  display: "flex",
  background: Colors.shaft,
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  alignItems: "center",
  zIndex: 99,
  borderTop: `1px solid ${Colors.border}`,
}));
export const ActionIconsContainerDesktop = styled(Box)(() => ({
  flexGrow: 0,
}));
export const DrawerCloseButton = styled(IconButton)(() => ({
  position: "absolute",
  top: 10,
  left: "300px",
  zIndex: 1999,
}));
export const LogoImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "165px",
  height: "279px",
  [theme.breakpoints.down("lg")]: {
    width: "155px",
    height: "270px",
  },
  // [theme.breakpoints.down("sm")]: {
  //   width: "124px",
  //   height: "174px",
  // },
}));
export const MyMenuLinkMobile = styled(Link)(() => ({
  textDecoration: "none",
  flexGrow: 1,
  color: Colors.white,
  "&:hover": {
    background: lighten(0.05, Colors.primary),
    color: Colors.white,
  },
  "&:active": {
    background: lighten(0.05, Colors.primary),
  },
  "&:focus,  &:visited, &:link, ": {
    textDecoration: "none",
  },
}));
