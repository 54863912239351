import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import BingMapsReact from "bingmaps-react";

export default function MapRoutes(props) {
  const dispatch = useDispatch();

  const [markersDataList, setmMrkersDataList] = React.useState(null);

  useEffect(() => {
    if (markersDataList == null) {
      setmMrkersDataList({
        latitude: 47.91633195,
        longitude: 106.90669610658803,
      });
    }
  }, [markersDataList]);
  const pushPin = {
    center: {
      latitude: props.lat || 47.91633195,
      longitude: props.lng || 106.90669610658803,
    },
    options: {
      title: props.name || "",
    },
  };

  const pushPins = [pushPin];
  return (
    <>
      <BingMapsReact
        bingMapsKey={process.env.REACT_APP_BING_API_KEY}
        height="411px"
        onMapReady={(e) => {
          // console.log(e);
        }}
        mapOptions={{
          navigationBarMode: "square",
          enableClickableLogo: true,
          liteMode: true,
          enableHighDpi: true,
          showMapTypeSelector: true,
          showScalebar: true,
          showTrafficButton: true,
          showTermsLink: true,
        }}
        width="100%"
        pushPins={pushPins}
        viewOptions={{
          center: markersDataList,
          mapTypeId: "grayscale",
          zoom: 10,

          customMapStyle: {
            elements: {
              area: { fillColor: "#b6e591" },
              water: { fillColor: "#75cff0" },
              tollRoad: { fillColor: "#a964f4", strokeColor: "#a964f4" },
              arterialRoad: { fillColor: "#ffffff", strokeColor: "#d7dae7" },
              road: { fillColor: "#ffa35a", strokeColor: "#ff9c4f" },
              street: { fillColor: "#ffffff", strokeColor: "#ffffff" },
              transit: { fillColor: "#000000" },
            },
            settings: {
              landColor: "#efe9e1",
            },
          },
        }}
      />
      {/* <BingMapsReact
        bingMapsKey="Asq95sK5MUJ5odisZmlSfXlz3X0wT0FyOnQvr16zA8_mIaMtIEUCGz1isrtK18OB"
        // pushPins={pushPin}
        viewOptions={{ center: { latitude: 27.98785, longitude: 86.925026 } }}
      /> */}
    </>
  );
}
