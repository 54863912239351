// eslint-disable-next-line no-unused-vars
import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MementoContainer } from "../../styles/home";

export const Memento = ({ matches, ui, isDark }) => {
  return (
    <>
      <Container sx={{ color: "text.primary" }} maxWidth={"xl"}>
        <Stack direction={"column"} alignItems={"center"}>
          <MementoContainer isDark={isDark}>
            <Box marginRight={2}>
              {!isDark ? (
                <img src="../../../assets/mementoIcon.svg" alt="" />
              ) : (
                <img src="../../../assets/mementoIconDark.svg" alt="" />
              )}
            </Box>
            <Stack>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: matches ? ui.fontContent + "px" : ui.fontTitle1,
                }}
              >
                {" "}
                Санамж
              </Typography>
              <Typography
                sx={{
                  color: "#868D94",
                  fontWeight: 600,
                  fontSize: matches ? ui.fontSmall + "px" : ui.fontContent,
                }}
              >
                Та төрийн байгууллагад гомдол, мэдээлэл хүргүүлэхдээ бусдын эрх,
                эрх чөлөө, хууль ёсны ашиг сонирхлыг хүндэтгэж, гомдол
                мэдээлэлтэй холбоотой эрх зүйн үр дагаварыг хариуцах болохыг
                анхаарна уу.
              </Typography>
            </Stack>
          </MementoContainer>

          <Box
            sx={{
              color: !isDark ? "#0D1B2A" : "#49545F",
              fontWeight: 600,
              fontSize: ui.fontContent,
            }}
          >
            Та санал хүсэлт илгээхээсээ өмнө{" "}
            <a
              href="#"
              style={{
                textDecoration: "none",
              }}
            >
              ДАН системээр нэвтрэх шаардлагатайг{" "}
            </a>
            анхаарна уу!!!
          </Box>
        </Stack>
      </Container>
    </>
  );
};
