import { Close, Height, Link } from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import { lighten } from "polished";

import { DrawerCloseButton, MyMenuLinkMobile } from "../../styles/appbar";
import { Colors } from "../../styles/theme";
import { useUIContext } from "../../context/ui";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerOpen } from "../../app/uiSlice";

import Actions from "../appBar/actions";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;
export default function AppDrawer({ openLogin, closeLogin }) {
  //const { drawerOpen, setDrawerOpen } = useUIContext();
  const ui = useSelector((state) => state.ui);
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const { drawerOpen } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    dispatch(setDrawerOpen(newOpen));
  };
  const toProfile = () => {
    // console.log("sdfsdfsd");
    navigate("/profile");
  };
  return (
    <>
      {/* {drawerOpen && (
        <DrawerCloseButton onClick={() => dispatch(setDrawerOpen(false))}>
          <Close
            sx={{ fontSize: "2.5rem", color: lighten(0.09, Colors.secondary) }}
          />
        </DrawerCloseButton>
      )} */}

      <Drawer
        elevation={0}
        variant={"temporary"}
        onClose={toggleDrawer(false)}
        open={drawerOpen}
      >
        <Box
          sx={{
            width: "300px",
            // backgroundColor: Colors.white,
            height: "100%",

            // borderRadius: "0px 100px 100px 0px",
            // borderRight: `1px solid ${Colors.secondary}`,
          }}
        >
          <Actions />
          <List>
            <MyMenuLinkMobile to={"/"}>
              <ListItemButton
                onClick={() => {
                  console.log("first");
                  toggleDrawer(false);
                }}
              >
                <Box sx={{ mr: 1 }}>
                  {" "}
                  {theme.palette.mode === "dark" ? (
                    <img src="../../../assets/homeIconDark.svg" alt="" />
                  ) : (
                    <img src="../../../assets/homeIcon.svg" alt="" />
                  )}
                </Box>
                <ListItemText
                  sx={{
                    color: "text.primary",
                    "& span": {
                      color: "text.primary",
                      fontSize: `${ui.fontContent}px`,
                    },
                  }}
                >
                  Нүүр
                </ListItemText>
              </ListItemButton>
            </MyMenuLinkMobile>
            <MiddleDivider />
            <MyMenuLinkMobile to={"/advince"}>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(false);
                }}
              >
                <Box sx={{ mr: 1 }}>
                  {" "}
                  {theme.palette.mode === "dark" ? (
                    <img src="../../../assets/helpIconDark.svg" alt="" />
                  ) : (
                    <img src="../../../assets/helpIcon.svg" alt="" />
                  )}
                </Box>
                <ListItemText
                  sx={{
                    color: "text.primary",
                    "& span": {
                      color: "text.primary",
                      fontSize: `${ui.fontContent}px`,
                    },
                  }}
                >
                  Зөвлөмж
                </ListItemText>
              </ListItemButton>
            </MyMenuLinkMobile>

            {/* <MiddleDivider />
            <MyMenuLinkMobile to={"/"}>
              <ListItemButton
                onClick={() => {
                  //  setDrawerOpen(false);
                }}
              >
                <Box sx={{ mr: 1 }}>
                  {" "}
                  {theme.palette.mode === "dark" ? (
                    <img src="../../../assets/contactIconDark.svg" alt="" />
                  ) : (
                    <img src="../../../assets/contactIcon.svg" alt="" />
                  )}
                </Box>
                <ListItemText
                  sx={{
                    color: "text.primary",
                    "& span": {
                      color: "text.primary",
                      fontSize: `${ui.fontContent}px`,
                    },
                  }}
                >
                  Холбоо барих
                </ListItemText>
              </ListItemButton>
            </MyMenuLinkMobile> */}
            <MiddleDivider />
            <MyMenuLinkMobile to={user.isLogged ? "/profile" : "/"}>
              <ListItemButton
                onClick={() => {
                  //  setDrawerOpen(false);
                  if (user.isLogged) {
                    toProfile();
                  } else {
                    openLogin();
                    //loginDan();
                  }
                }}
              >
                <Box sx={{ mr: 1 }}>
                  {" "}
                  {theme.palette.mode === "dark" ? (
                    <img src="../../../assets/loginUserIconDark.svg" alt="" />
                  ) : (
                    <img src="../../../assets/loginUserIcon.svg" alt="" />
                  )}
                </Box>
                <ListItemText
                  sx={{
                    color: "text.primary",
                    "& span": {
                      color: "text.primary",
                      fontSize: `${ui.fontContent}px`,
                    },
                  }}
                  primary={
                    user.isLogged && user.user !== null
                      ? user.user.lastname.substring(0, 1) +
                        "." +
                        user.user.firstname
                      : "Нэвтрэх"
                  }
                />

                {/* </ListItemText> */}
              </ListItemButton>
            </MyMenuLinkMobile>
            <MiddleDivider />
          </List>
        </Box>
      </Drawer>
    </>
  );
}
