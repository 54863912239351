import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import AppbarMobile from "./appbarMobile";
import AppbarDesktop from "./appBarDesktop";
import { useSelector } from "react-redux";

export default function AppBar({ openLogin, closeLogin }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const ui = useSelector((state) => state.ui);
  return (
    <>
      {matches ? (
        <AppbarMobile
          matches={matches}
          ui={ui}
          openLogin={openLogin}
          closeLogin={closeLogin}
        />
      ) : (
        <AppbarDesktop
          ui={ui}
          matches={matches}
          openLogin={openLogin}
          closeLogin={closeLogin}
        />
      )}
    </>
  );
}
