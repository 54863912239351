import { Grid, Box } from "@mui/material";

import Skeleton from "@mui/material/Skeleton";

export default function StelektonListData(data) {
  //   const theme = useTheme();
  //   const matches = useMediaQuery(theme.breakpoints.down("md"));
  //console.log(data.data);
  const skeletonList = (count) => {
    return (
      <>
        {Array.apply(0, Array(3)).map(function (x, i) {
          return (
            <Grid
              key={i}
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              flexDirection={"column"}
              alignItems="start"
            >
              {/* <Skeleton variant="rectangular" width={210} height={118} />{" "} */}
              <Box sx={{ pt: 0.5 }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Box>
            </Grid>
          );
        })}
      </>
    );
  };

  // if (isLoad) {

  return (
    <>
      <Grid
        item
        container
        spacing={{ xs: 2, md: 4, lg: 2 }}
        justifyContent={"start"}
        sx={{ margin: "20px 4px 10px 4px" }}
        columns={{ xs: 4, sm: 8, md: 9, lg: 9 }}
      >
        {skeletonList(data.data)}
      </Grid>
    </>
  );
}
