import { styled } from "@mui/material/styles";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Colors } from "../theme";

export const BannerContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  height: "100%",

  //  background: Colors.light_gray,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
}));

export const BannerImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  marginTop: "2rem",
  //width: "600px",
  height: "269px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    // display: "none",
    height: "169px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "200px",
  },
}));
export const BannerContent = styled(Box)(() => ({
  //display: "flex",

  //flexDirection: "column",
  justifyContent: "center",

  color: "white",
  maxWidth: 760,
  padding: "30px",
}));
export const BannerTitle = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,

  marginBottom: "20px",
  [theme.breakpoints.down("sm")]: {},
}));
export const BannerDescription = styled(Typography)(({ theme }) => ({
  lineHeight: 1.35,
  textAlign: "justify",
  marginBottom: "3em",

  [theme.breakpoints.down("md")]: {
    lineHeight: 1.15,
    letterSpaceing: 1.15,
    marginBottom: "1.5em",
  },
}));
export const BannerShopButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "color",
  name: "MyShopButton",
  slot: "Root",

  overridesResolver: (props, styles) => [
    styles.root,
    props.color === "primary" && styles.primary,
    props.color === "secondary" && styles.primary,
  ],
})(({ theme }) => ({
  textTransform: "none",
  //width: "201px",
  height: "44px",
  padding: "20px 10px",
  background: Colors.secondary,
  "&:hover": {
    background: Colors.secondary,
  },

  fontWeight: "bold",

  borderRadius: "8px",

  [theme.breakpoints.down("md")]: {
    padding: "20px 10px",
    fontSize: "14px",
  },
}));
