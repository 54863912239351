import {
  Box,
  Button,
  DialogActions,
  Grid,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ColorModeContext, Colors, tokens } from "../../styles/theme";
import { useContext, useEffect, useState } from "react";
import { useUIContext } from "../../context/ui";
import { useDispatch, useSelector } from "react-redux";
import { getViolation, grievanceMyFun } from "../../feutures/auth/userSlice";
import {
  KeyboardArrowDown,
  KeyboardDoubleArrowDown,
} from "@mui/icons-material";
import { FeedBackMyContainer } from "../../styles/feedBackMy";
import BaseHelper from "../../helper/BaseHelper";
import StelektonListData from "../../utils/skeletonList";
//import image from "/images/Truck.png";
export default function VailotionListPage({ ui, matches, setSelectPage }) {
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const theme = useTheme();
  const violationData = useSelector((state) => state.user.violationData);
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [formData, setFormData] = useState([]);
  const loading = useSelector((state) => state.home.loading);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage] = useState(3);
  const [pagesize, setPagesize] = useState(3);
  useEffect(() => {
    if (violationData === null) {
      dispatch(getViolation());
    } else {
      setFormData(violationData);
    }
  }, [dispatch, violationData]);
  //console.log(formData);
  useEffect(() => {
    if (formData !== null) {
      setPagesize(Math.ceil(formData.length / cardsPerPage));
    }
  }, [formData]);
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards =
    formData !== null ? formData.slice(indexOfFirstCard, indexOfLastCard) : [];
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  // console.log(violationData);
  return (
    <Stack direction={"column"} spacing={3}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h6" sx={{ color: "text.primary" }}>
          Зөрчлийн жагсаалт
        </Typography>
        <Box
          sx={{
            padding: "4px 4px 4px 8px",
            alignItems: "center",
            borderRadius: "8px",
            border: "1px solid",
            display: "flex",
          }}
        >
          <Typography sx={{ fontSize: ui.fontContent + "px" }}>
            {" "}
            Эрэмбэлэх
          </Typography>
          <KeyboardArrowDown />
        </Box>
      </Stack>
      {loading && violationData === null ? (
        <>
          <StelektonListData />
        </>
      ) : violationData === null ? (
        <Stack
          direction={"column"}
          justifyContent={"center"}
          spacing={12}
          alignSelf={"center"}
        >
          <Typography
            variant="body1"
            sx={{ color: "#C2C6CA" }}
            textAlign={"center"}
          >
            Одоогоор таньд бүртгэсэн зөрчил алга байна
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <Button
              size="medium"
              variant="contained"
              onClick={() => {
                setSelectPage("addVailotion");
              }}
              sx={{
                // width: "40%",
                fontSize: ui.fontContent + "px",
                fontWeight: 600,
                color: "#fff",
                textTransform: "none",
                background: "#0B3599",
                pl: "70px",
                pr: "70px",
                pb: "12px",
                pt: "12px",
                mb: "1px",
                borderRadius: "8px",
                "&:hover": {
                  background: "#0B3599",
                },
              }}
            >
              Зөрчил бүртгэх
            </Button>
          </Box>
        </Stack>
      ) : (
        currentCards.map((item, index) => (
          <FeedBackMyContainer
            isDark={theme.palette.mode === "light" ? false : true}
          >
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={6} md={4}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Үйлчилгээ
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.service_type_name}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Зөрчил гаргагч
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {`${item.offender_lastname.substring(0, 1)}.${
                    item.offender_firstname
                  }`}
                </Typography>
              </Grid>

              <Grid item xs={"auto"}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                    textAlign: matches ? "start" : "end",
                  }}
                >
                  Зөрчил бүртгэсэн
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.checked}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Зөрчлийн дугаар
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.number}
                </Typography>
              </Grid>
              <Grid item xs={"auto"} md={"auto"}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                    textAlign: matches ? "start" : "end",
                  }}
                >
                  Хуулийн заалт
                </Box>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.legal_penalty_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={6} md={4}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Хэрэг
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.decision_type_name}
                </Typography>
              </Grid>
              <Grid item xs={"auto"} md={"auto"}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                    textAlign: matches ? "start" : "end",
                  }}
                >
                  Хуулийн зүйл, хэсэг
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.legal_provision_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={6} md={4}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Зөрчил гаргагч
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.company_name}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Улсын дугаар
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.plate_no}
                </Typography>
              </Grid>

              <Grid item xs={"auto"}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                    textAlign: matches ? "start" : "end",
                  }}
                >
                  Илрүүлэлт
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.detection_type_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={6} md={4}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Төлбөр
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {BaseHelper.currancyFormat(
                    parseInt(item.legal_penalty_amount)
                  )}
                </Typography>
              </Grid>

              <Grid item xs={6} md={4}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                  }}
                >
                  Зөрчил
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                  }}
                >
                  {item.type_name}
                </Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <Box
                  sx={{
                    color:
                      theme.palette.mode === "light" ? "#868D94" : "#868D94",
                    fontSize: ui.fontSmall + "px",
                    textAlign: matches ? "start" : "end",
                  }}
                >
                  Зөрчил үйлдсэн огноо
                </Box>
                <Typography
                  sx={{
                    fontSize: ui.fontContent + "px",
                    color: "text.primary",
                    textAlign: matches ? "start" : "end",
                  }}
                >
                  {BaseHelper.getDateToStrFromStr(item.violation_date)}
                </Typography>
              </Grid>
            </Grid>
          </FeedBackMyContainer>
        ))
      )}
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Pagination
          variant="outlined"
          shape="rounded"
          color="primary"
          defaultPage={1}
          count={pagesize}
          page={currentPage}
          onChange={handleChange}
        />
      </Box>
    </Stack>
  );
}
