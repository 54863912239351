import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { ServicesContainer } from "../../styles/home";
import useDialogModal from "../../hooks/useDailogModal";
import AddGrivance from "../dialog/grivance";
export default function SingleServicesDesktop({
  item,
  matches,
  ui,
  isLogged,
  loginDialog,
}) {
  //const travelCartCount = useSelector((state) => state.travel.travelCartCount);
  //console.log(item);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [GrivanceDetialDialog, showGrivanceDialog, closeDialog] =
    useDialogModal(AddGrivance);
  return (
    <>
      {/* <img
          src={`../../../../assets/service/${product.webIcon}`}
          alt=""
          style={{ width: "260px" }}
        /> */}
      <ServicesContainer
        isDark={theme.palette.mode === "light" ? false : true}
        elevation={1}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={
          matches
            ? { width: "100%", height: "auto", borderRadius: "10px" }
            : {
                width: "100%",
                //height: "453px",
                height: "520px",
                borderRadius: "10px",
              }
        }
      >
        <CardActionArea onClick={() => console.log("first")}>
          <Box sx={{ pt: "16px", pl: "56px", pr: "56px" }}>
            <CardMedia
              component="img"
              alt="Contemplative Reptile"
              image={
                item.id === 3
                  ? "../../../assets/thanks.svg"
                  : item.id === 2
                  ? "../../../assets/idea.svg"
                  : item.id === 4
                  ? "../../../assets/news.svg"
                  : item.id === 1
                  ? "../../../assets/angry.svg"
                  : ""
              }
              // height={"181px"}
              // image={`../../../../assets/logo.svg`}
              title="Contemplative Reptile"
            />
          </Box>

          <CardContent>
            <Typography
              gutterBottom
              variant="h3"
              component="h2"
              sx={{
                fontSize: ui.fontTitle1 + "px",
                fontWeight: 700,
                mb: "20px",
              }}
            >
              {item.name}
            </Typography>
            <Typography
              sx={{
                fontSize: ui.fontContent + "px",
                fontWeight: 500,
                textAlign: "justify",
                color: "text.primary",
              }}
              component="p"
            >
              {item.note}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="medium"
            variant="contained"
            onClick={() => {
              if (isLogged) {
                showGrivanceDialog();
              } else {
                loginDialog();
              }
            }}
            sx={{
              fontSize: ui.fontContent + "px",
              fontWeight: 600,
              color: "#fff",
              textTransform: "none",
              background: "#0B3599",
              pl: "70px",
              pr: "70px",
              pb: "12px",
              pt: "12px",
              mb: "16px",
              borderRadius: "8px",
              "&:hover": {
                background: "#0B3599",
              },
            }}
          >
            {item.id === 3
              ? "Талархал илгээх"
              : item.id === 2
              ? "Санал хүсэлт илгээх"
              : item.id === 4
              ? "Мэдээлэл илгээх"
              : item.id === 1
              ? "Гомдол илгээх"
              : ""}
          </Button>
        </CardActions>
        <GrivanceDetialDialog item={item} />
      </ServicesContainer>
    </>
  );
}
