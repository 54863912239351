import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import {
  BannerContainer,
  BannerContent,
  BannerDescription,
  BannerImage,
  BannerShopButton,
  BannerTitle,
} from "../../styles/banner";

import { ColorModeContext, Colors, tokens } from "../../styles/theme";
import { useContext } from "react";
import { useUIContext } from "../../context/ui";
//import image from "/images/Truck.png";
export default function ProfilePage({ user, ui, matches }) {
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("md"));

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Stack direction={"column"} spacing={2}>
      <Typography variant="h6" sx={{ color: "text.primary" }}>
        Хэрэглэгчийн хувийн мэдээлэл
      </Typography>
      <Stack direction={matches ? "column" : "row"} spacing={1}>
        <Stack direction={"column"} sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: ui.fontSmall + "px",
              // ml: 1,
              color: "text-primary",
            }}
          >
            Ургийн овог
          </Typography>
          <Box
            sx={{
              // width: "338px",
              // height: "44px",
              padding: "12px 16px 12px 16px",
              background: theme.palette.mode === "dark" ? "#25323F" : "#E7E8EA",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{
                color: "#868D94",
                fontWeight: 600,
                fontSize: ui.fontContent + "px",
              }}
            >
              {user.surname}
            </Typography>
          </Box>
        </Stack>
        <Stack direction={"column"} sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: ui.fontSmall + "px",
              // ml: 1,
              color: "text-primary",
            }}
          >
            Овог
          </Typography>
          <Box
            sx={{
              // width: "338px",
              // height: "44px",

              padding: "12px 16px 12px 16px",
              background: theme.palette.mode === "dark" ? "#25323F" : "#E7E8EA",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{
                color: "#868D94",
                fontWeight: 600,
                fontSize: ui.fontContent + "px",
              }}
            >
              {user.lastname}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Stack direction={matches ? "column" : "row"} spacing={1}>
        <Stack direction={"column"} sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: ui.fontSmall + "px",
              // ml: 1,
              color: "text-primary",
            }}
          >
            Нэр
          </Typography>
          <Box
            sx={{
              // width: "338px",
              // height: "44px",
              padding: "12px 16px 12px 16px",
              background: theme.palette.mode === "dark" ? "#25323F" : "#E7E8EA",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{
                color: "#868D94",
                fontWeight: 600,
                fontSize: ui.fontContent + "px",
              }}
            >
              {user.firstname}
            </Typography>
          </Box>
        </Stack>
        <Stack direction={"column"} sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: ui.fontSmall + "px",
              // ml: 1,
              color: "text-primary",
            }}
          >
            Регистрийн дугаар
          </Typography>
          <Box
            sx={{
              // width: "338px",
              // height: "44px",

              padding: "12px 16px 12px 16px",
              background: theme.palette.mode === "dark" ? "#25323F" : "#E7E8EA",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{
                color: "#868D94",
                fontWeight: 600,
                fontSize: ui.fontContent + "px",
              }}
            >
              {user.regnum}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
