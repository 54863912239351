import { createTheme } from "@mui/material/styles";
import { lighten } from "polished";
import { createContext, useState, useMemo } from "react";

export const Colors = {
  primary: "#0B3599",
  dark: "#0D1B2A",
  secondary: "#FFFFFF",
  background: "#F6F6FA",
  success: "#4CAF50",
  info: "#00a2ff",
  danger: "#FF5722",
  warning: "FFC107",
  dark: "#0e1b20",
  light: "#757ce8",
  muted: "#abafb3",
  border: "#DDDFE1",
  inverse: "#2F3D4A",
  shaft: "rgb(248, 248, 248)",
  grey: "#898999",
  line: "#EEEFF2",
  dim_gray: "#696969",
  dove_gray: "d5d5d5",
  body_bg: "#f3f6f9",
  light_gray: "rgb(230,230,230)",

  white: "#FFFFFF",
  black: "#888A99",
};
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        grey: {
          100: "#0e1b20",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#c3caeb",
          200: "#9ba8dd",
          300: "#7286cf",
          400: "#516bc5",
          500: "#2b51bb",
          600: "#254ab1",
          700: "#1840a5",
          800: "#0B3599",
          900: "#002485",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
      }),
});
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    // MuiDrawer: {
    //   styleOverrides: {
    //     paper: {
    //       width: 400,
    //       background: Colors.white,
    //       // color: Colors.white,
    //       borderRadius: "0px 100px 0px 0px",
    //       borderRight: `1px solid ${Colors.secondary}`,
    //     },
    //   },
    // },

    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              // main: colors.primary[800],
              main: colors.primary[100],
            },
            secondary: {
              light: "#ffad42",
              main: "#f57c00", // Orange[700]
              dark: "#bb4d00",
              contrastText: "#000",
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
            // action: {
            //   active: colors.grey[500],
            // },
          }
        : {
            // palette values for light mode
            primary: {
              main: "#0D1B2A",
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
            text: {
              ...(mode === "light"
                ? {
                    primary: "#0D1B2A",
                    secondary: colors.primary[800],
                    thirthText: "#0D1B2A",
                  }
                : {
                    primary: "#fff",
                    secondary: "#fff",
                    thirthText: "#49545F",
                  }),
            },
          }),
    },
    // typography: {
    //   fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //   fontSize: 12,
    //   h1: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 40,
    //   },
    //   h2: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 32,
    //   },
    //   h3: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 24,
    //   },
    //   h4: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 20,
    //   },
    //   h5: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 16,
    //   },
    //   h6: {
    //     fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    //     fontSize: 14,
    //   },
    // },
  };
};
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: Colors.primary,
//     },
//     secondary: {
//       main: Colors.secondary,
//     },
//   },
//   components: {
//     MuiButton: {
//       defaultProps: {
//         disableRipple: true,
//         disableElevation: true,
//       },
//     },

//     MuiDrawer: {
//       styleOverrides: {
//         paper: {
//           width: 400,
//           background: Colors.white,
//           // color: Colors.white,
//           // borderRadius: "0px 100px 0px 0px",
//           // borderRight: `1px solid ${Colors.secondary}`,
//         },
//       },
//     },
//     overrides: {
//       MuiAvatar: {
//         img: {
//           // handle correctly non-square images
//           objectFit: "cover",
//           height: "100%",
//         },
//       },
//     },
//     MuiDivider: {
//       styleOverrides: {
//         root: {
//           borderColor: lighten(0.2),
//         },
//       },
//     },
//     MyShopButton: {
//       styleOverrides: {
//         root: {
//           color: Colors.dark,
//         },
//         primary: {
//           //   background: ,
//           "&:hover": {
//             background: lighten(0.05),
//           },
//         },
//         secondary: {
//           // background: Colors.secondary,
//           "&:hover": {
//             background: lighten(0.05),
//           },
//         },
//       },
//     },
//   },
// });

//export default theme;
